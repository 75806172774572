import { makeAutoObservable, runInAction } from "mobx"
import agent from "../api/agent"
import DeckChild from "../models/deckChild"
import DeckCardInfo from "../models/deckCard"
import CardStore from "./cardStore"
import CacheService from "../../CacheService"

export type DeckChangePredicate =
  | "addFromImport"
  | "addCard"
  | "quantityChange"
  | "remove"
  | "changeName"
  | "changeFormat"
  | "changeDeckArchetype"
  | "addContent"
  | "isBO1"
  | "deleteContent"
  | "contentSourceId"
  | "colorCombination"
  | "deckLatestState";

export default class DeckChildStore {
  deckChild: DeckChild = DeckChild.createEmptyObject()
  deckListItems: DeckChild[] = []
  editMode = false
  loading = false
  updating = false
  loadingInitial = false
  predicate = new Map().set('all', true)

  constructor(private cardStore: CardStore) {
    makeAutoObservable(this)
  }

  loadDeckChildById = async (id: number) => {
    this.setLoading(true)
    try {
      const deckDto = await agent.DeckChild.getDeckChildById(id)
      runInAction(() => this.deckChild = DeckChild.createFromAnyObject(deckDto))
    } catch (error) {
      console.log(error)
      throw error
    } finally {
      this.setLoading(false)
    }
  }

  deleteDeckChildById = async (): Promise<boolean> => {
    this.setLoading(true)
    var deleted = false
    try {
      deleted = await agent.DeckChild.deleteDeckChild(this.deckChild.id)
      runInAction(() => this.deckChild = DeckChild.createEmptyObject())
    } catch (error) {
      console.log(error)
      throw error
    } finally {
      this.setLoading(false)
    }
    return deleted
  }

  loadDeckChildList = async (formatId: number, bestOfOne: boolean) => {
    this.setLoading(true)
    try {
      let response = await agent.DeckChild.getAllDeckChildListItems(formatId, bestOfOne)
      let deckListItems = response.map(listItem => DeckChild.createFromAnyObject(listItem))
      runInAction(() => {
        this.deckListItems = deckListItems
      })
    } catch (error: any) {
      console.error("Failed to fetch deck child items:", error)
      throw new Error("Error fetching deck child items")
    } finally {
      this.setLoading(false)
    }
  }

  createDeckOrUpdate = async (): Promise<DeckChild> => {
    this.setUpdating(true)
    try {
      let deckChild: any
      if (this.deckChild.id === 0) {
        deckChild = await agent.DeckChild.postDeckChild(this.deckChild.convertToPostDeckChildRequestDto())
      } else {
        const toRequestDeck = this.deckChild.convertToPatchDeckChildRequestDto()
        deckChild = await agent.DeckChild.patchDeckChild(toRequestDeck)
      }
      runInAction(() => this.deckChild = DeckChild.createFromAnyObject(deckChild))
      return deckChild
    } catch (error: any) {
      console.error("Error creating or updating deck child:", error)
      throw error
    } finally {
      this.setUpdating(false)
    }
  }

  onDeckChangeHandler = (predicate: DeckChangePredicate, payload: any) => {
    
    let updatedDeck
    switch (predicate) {
      case "addFromImport":
        this.addCardsFromImport(payload)
        return
      case "addCard":
        this.addCardToDeck(payload)
        return
      case "quantityChange":
        updatedDeck = { ...this.deckChild }
        updatedDeck.deckCardInfos = this.deckChild.deckCardInfos.map(deckCardInfo =>
          deckCardInfo.compositeKey === payload.uniqueId
            ? DeckCardInfo.createFromAnyObject({ ...deckCardInfo, quantity: payload.value })
            : deckCardInfo
        )
        break
      case "remove":
        updatedDeck = { ...this.deckChild }
        updatedDeck.deckCardInfos = this.deckChild.deckCardInfos.filter(deckCardInfo =>
          deckCardInfo.compositeKey !== payload
        )
        break
      case "changeName":
        updatedDeck = { ...this.deckChild, name: payload }
        break
      case "changeFormat":
        updatedDeck = { ...this.deckChild, formatId: payload, deckArchetypeId: undefined }
        break
      case "changeDeckArchetype":
        updatedDeck = { ...this.deckChild, deckArchetypeId: payload }
        break
      case "addContent":
        var foundContent = this.deckChild.contents.find(content => content.id === payload.id)
        if (foundContent) return
        updatedDeck = { ...this.deckChild, contents: [...this.deckChild.contents, payload] }
        break
      case "isBO1":
        updatedDeck = { ...this.deckChild, bestOfOne: payload }
        break
      case "deleteContent":
        updatedDeck = { ...this.deckChild, contents: [...this.deckChild.contents.filter(content => content.id !== payload)] }
        break
      case "contentSourceId":
        var content = this.deckChild.contents.map(content => { content.isSource = !content.isSource && content.id === payload; return content })
        updatedDeck = { ...this.deckChild, contents: content }
        break
      case "colorCombination":
        updatedDeck = { ...this.deckChild, colorCombinationId: payload }
        break
      case "deckLatestState":
        updatedDeck = { ...this.deckChild, deckLatestState: payload }
        break
      default:
        console.warn(`Unsupported change type: ${payload}`)
        return
    }
    this.setDeck(DeckChild.createFromObject(updatedDeck))
  }

  private addCardsFromImport = async (deckCardInfos: DeckCardInfo[]) => {
    if(deckCardInfos.length === 0) return

    try {
      const completeCardInfos = await agent.Cards.GetCardInfoFromListOfCards(deckCardInfos.map(card => card.name ))

      if (completeCardInfos != null && completeCardInfos.foundCards) {
        deckCardInfos.forEach(deckCard => {
          const cardNameLower = deckCard.name.toLowerCase();
          let completeCardInfo = null;
      
          // Iterate over the completeCardInfos array to find a regex match
          for (let info of completeCardInfos.foundCards) {
            const nameIdLower = info.nameId.toLowerCase();
            const regex = new RegExp(`^(${cardNameLower}$|${cardNameLower} //)`, 'i');
            if (regex.test(nameIdLower)) {
              completeCardInfo = info;
              break; // Stop once we find the first match
            }
          }
          
          if (completeCardInfo) {
            deckCard.setManaCost(completeCardInfo.manaCost);
            deckCard.types = DeckCardInfo.findCardTypes(completeCardInfo.type);
            deckCard.name = completeCardInfo.nameId;
          }
        });
      }

      const updatedDeck = DeckChild.createFromAnyObject({
        ...this.deckChild,
        deckCardInfos: deckCardInfos,
      })
      this.setDeck(updatedDeck)

    } catch (error) {
      console.error('Error fetching complete card info:', error)
    }
  }
  

  private addCardToDeck = async (deckCardInfo: DeckCardInfo | null) => {

    if (deckCardInfo) {
      const cardExists = this.deckChild.deckCardInfos.some(existingDeckCardInfo => existingDeckCardInfo.compositeKey === deckCardInfo.compositeKey)

      if (!cardExists) {
        const updatedDeck = DeckChild.createFromAnyObject({
          ...this.deckChild,
          deckCardInfos: [...this.deckChild.deckCardInfos, deckCardInfo],
        })

        try {
          const completeCardInfo = await agent.Cards.getCardByNameId(deckCardInfo.name)
          if (completeCardInfo) {
            const updatedDeckWithCompleteInfo = DeckChild.createFromObject({
              ...updatedDeck,
              deckCardInfos: updatedDeck.deckCardInfos.map(existingDeckCardInfo => {
                if (existingDeckCardInfo.compositeKey === deckCardInfo.compositeKey) {
                  return DeckCardInfo.createFromAnyObject({ ...deckCardInfo, ...completeCardInfo })
                }
                return existingDeckCardInfo
              }),
            })
            this.setDeck(updatedDeckWithCompleteInfo)
          }
        } catch (error) {
          console.error('Error fetching complete card info:', error)
        }
      }
    }
  }

  handleCheckboxChange = (tagId: number) => {
    const selectedTag = CacheService.getInstance().tags[tagId]

    if (selectedTag) {

      const isSelected = this.deckChild.tagIds.some((objTagId) => objTagId === tagId)

      const newTags = isSelected
        ? this.deckChild.tagIds.filter((objTagId) => objTagId !== tagId)
        : [...this.deckChild.tagIds, tagId]

      const updatedDeck = DeckChild.createFromObject({
        ...this.deckChild,
        tagIds: newTags,
      })

      this.setDeck(updatedDeck)
    }
  }

  setDeck(deckChild: DeckChild) {
    this.deckChild = deckChild
  }

  emptyTheDeck() {
    runInAction(() => this.deckChild = DeckChild.createEmptyObject())
  }

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state
  }

  setLoading = (state: boolean) => {
    this.loading = state
  }

  setUpdating = (state: boolean) => {
    this.updating = state
  }
}