import { Segment, Header, Dropdown, Grid, GridRow, GridColumn, DropdownMenu, DropdownItem } from 'semantic-ui-react'
import DeckChildStore from '../../../app/stores/deckChildStore'
import CacheService from '../../../CacheService'
import RenderManaCost from '../../../app/common/RenderManaCost'

const AddColorCombinationFieldComponent = ({ deckStore } : { deckStore : DeckChildStore }) => {
    var selectedItem = undefined
    if (deckStore.deckChild.colorCombinationId !== 0) {
        const colorCombination = CacheService.getInstance().colorCombinations[deckStore.deckChild.colorCombinationId]
        selectedItem = <>{RenderManaCost({colors : colorCombination.colors})} <span>{colorCombination.name}</span> </>
    }

    return (
        <Segment raised>
            <Header as='h3'>Color</Header>
            <Grid>
            <GridColumn computer={8} tablet={8} mobile={16}>
                <GridRow>
                        <Dropdown text='Deck Colors' scrolling>
                            <DropdownMenu>
                                {Object.entries(CacheService.getInstance().colorCombinations).map(([key, value]) => (
                                    <DropdownItem
                                        key={Number(key)}
                                        value={Number(key)}
                                        text={<>{RenderManaCost({colors :value.colors})} {value.name}</>}
                                        onClick={() => deckStore.onDeckChangeHandler("colorCombination", Number(key))}
                                    />
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    </GridRow>
                </GridColumn>
                <GridColumn computer={8} tablet={8} mobile={16} style={{ display: 'flex', alignItems: 'center' }}>
                    <GridRow  style={{ display: 'flex', alignItems: 'center' }}>
                        {selectedItem && (
                            <span> {selectedItem} </span>
                        )}
                    </GridRow>
                </GridColumn>
            </Grid>
        </Segment>
    );
};

export default AddColorCombinationFieldComponent