import React, { useState } from 'react';
import { Form, Input, Select, Segment, Button, Grid, Modal } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../stores/store';
import CacheService from '../../../CacheService';
import YouTubeVideoComponent from '../../../features/Views/DeckArchetypeView/YoutubeVideoComponent';
import TwitterEmbedComponent from '../../../features/Views/DeckArchetypeView/TwitterEmbedComponent';

const ContentManager = observer(() => {
    const { contentCreatorStore } = useStore();
    const [modalValues, setModalValues] = useState<{platformId : number, url : string} | null>(null);

    const handlePlatformCheck = () => {
        if(modalValues === null) return <></>
        var type = CacheService.getInstance().platforms[modalValues.platformId]
                switch (type) {
            case 'Twitter':
                return <TwitterEmbedComponent tweetId={modalValues.url}/>
            case 'YouTube':
                return <YouTubeVideoComponent videoId={modalValues.url} />
            case 'Twitch':
                return <> Twitch embed </>                
            default:
                return <div>Unsupported platform or content not available</div>;
        }
    };

    return (
        <Segment>

            <Modal
                open={modalValues !== null}
                onClose={() => setModalValues(null)}
                size="small"
            >
                <Modal.Content>
                    {handlePlatformCheck()}
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setModalValues(null)}>Close</Button>
                </Modal.Actions>
            </Modal>
            <Form>
                <Grid>
                    {contentCreatorStore.contentCreator.contents.map(content =>
                        <Grid.Row columns={4} key={content.id}>
                            <Grid.Column>
                                <Form.Field>
                                    <label>Select Platform</label>
                                    <Select
                                        placeholder='Select your platform'
                                        options={Object.entries(CacheService.getInstance().platforms).map(([id, value]) => ({
                                            key: Number(id),
                                            value: Number(id),
                                            text: value
                                        }))}
                                        value={content.platformId}
                                        onChange={(_, { value }) => contentCreatorStore.onContentCreatorChangeHandler("updateContent", { ...content, platformId: value })}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column>
                                <Form.Field>
                                    <label>Content</label>
                                    <Input
                                        placeholder='Enter content here...'
                                        value={content.url}
                                        onChange={(e) => contentCreatorStore.onContentCreatorChangeHandler("updateContent", { ...content, url: e.target.value })}
                                    />
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column verticalAlign="bottom">
                                <Button color="red" onClick={() => contentCreatorStore.onContentCreatorChangeHandler("removeContent", content.id)}>
                                    Remove
                                </Button>
                            </Grid.Column>
                            <Grid.Column verticalAlign="bottom">
                                <Button onClick={() => setModalValues({platformId : content.platformId, url : content.url})}>
                                    Check Platform
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    )}
                    <Grid.Row>
                        <Grid.Column>
                            <Button onClick={() => contentCreatorStore.onContentCreatorChangeHandler("addContent", {})}>
                                Add Content
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
        </Segment>
    );
});

export default ContentManager;
