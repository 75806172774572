export interface News {
    deckChildId: number
    name: string
    formatId: number
    updatedAt: Date
    tagIds: number[]
    deckLatestState: number
    bestOfOne: boolean
    archetypeName?: string
    deckArchetypeId?: number
    content?: ContentWithCreator
}

export class News {
    id : string
    constructor(
        public deckChildId: number,
        public name: string,
        public formatId: number,
        public updatedAt: Date,
        public tagIds: number[],
        public deckLatestState: number,
        public archetypeName?: string,
        public deckArchetypeId?: number,
        public content?: ContentWithCreator,
    ) {
        this.id = deckChildId + (content ? "_" + content.id : "")
    }

    static createFromAnyObject(input: any): News {
        return new News(
            input.deckChildId ?? 0,
            input.name ?? '',
            input.formatId ?? 0,
            new Date(input.updatedAt ?? ''),
            input.tagIds ?? [],
            input.deckLatestState ?? 1,
            input.archetypeName ?? undefined,
            input.deckArchetypeId ?? undefined,
            input.content ? { 
                id : input.content.id, 
                contentCreatorId : input.content.contentCreatorId,
                contentCreatorName: input.content.contentCreatorId,
                title: input.content.title,
                url: input.content.url,
                platformId: input.content.platformId,
                publishDate: new Date(input.content.publishDate ?? ''),
            } : undefined,
        );
    }
}

interface ContentWithCreator {
    id: number
    contentCreatorId: number
    contentCreatorName: string
    title: string
    url: string
    platformId: number
    publishDate: Date
}

