import { useEffect, useRef, useState } from "react"
import { observer } from "mobx-react-lite"
import { useStore } from "../../../app/stores/store"
import { useNavigate, useParams } from "react-router-dom"
import Deck from "./DeckChildNormalListComponent"
import ToggleSwitch from "../../../app/common/toggleButton/ToggleSwitch"
import ArchetypeDeckChildListComponent from "./ArchetypeDeckChildListComponent"
import { Grid, Header } from "semantic-ui-react"
import DeckCardInfo from "../../../app/models/deckCard"
import DeckChild from "../../../app/models/deckChild"
import DeckArchetype from "../../../app/models/deckArchetype"
import CacheService from "../../../CacheService"

const DeckArchetypeViewUrlValidator = observer(() => {
    const navigate = useNavigate()
    const { deckArchetypeId: deckArchetypeIdStr, deckChildId : DeckChildIdStr, format : formatStr} = useParams<{ deckArchetypeId: string, deckChildId : string, format : string }>()
    const { type } = useParams()

    const bestOfOne = type === 'BO1' ? true
                    : type === 'BO3' ? false
                    : undefined

    var format : string | undefined = undefined;
    if (typeof formatStr !== "undefined" && formatStr in CacheService.getInstance().formatsByName) {
        format = formatStr
    }

    const { deckArchetypeStore } = useStore()
    const deckArchetypeId = deckArchetypeIdStr && !isNaN(Number(deckArchetypeIdStr)) ? Number(deckArchetypeIdStr) > 0 ? Number(deckArchetypeIdStr) : null : null

    useEffect(() => {
        const validateAndLoadDeckArchetype = async () => {

            if(bestOfOne === undefined){
                navigate(`/deckArchetypeView/BO3/${deckArchetypeId}/${DeckChildIdStr}`, { replace: true })
                return
            }

            if(format === undefined){
                navigate(`/deckList/`, { replace: true })
                return
            }

            if (deckArchetypeId === null || isNaN(deckArchetypeId) || deckArchetypeId <= 0) {
                navigate('/deckList/', { replace: true })
                return
            }

            if (deckArchetypeId === null || isNaN(deckArchetypeId) || deckArchetypeId <= 0) {
                navigate('/deckList', { replace: true })
                return
            }

            try {
                await deckArchetypeStore.loadDeckArchetype(deckArchetypeId, bestOfOne)
            } catch (error) {
                console.error('Failed to load deck archetype:', error)
                navigate('/deckList', { replace: true })
            }
        }

        validateAndLoadDeckArchetype()
    }, [deckArchetypeId, type, deckArchetypeStore, navigate, DeckChildIdStr, bestOfOne, format])

    if (deckArchetypeId === null) return null
    if (deckArchetypeStore.loadingInitial) return null
    if (!deckArchetypeStore.deckArchetype) return null
    if (deckArchetypeStore.deckArchetype.id === 0) return null
    if (deckArchetypeStore.deckArchetype.id !== deckArchetypeId) return null
    if (bestOfOne === undefined) return null
    if (bestOfOne === null) return null


    return <DeckArchetypeHandleDeckChildId deckArchetype={deckArchetypeStore.deckArchetype} />
})

const DeckArchetypeHandleDeckChildId = ({ deckArchetype }: { deckArchetype: DeckArchetype }) => {
    const navigate = useNavigate()
    const { deckChildId: deckChildIdStr } = useParams<{ deckChildId: string }>()
    const deckChildId = deckChildIdStr && !isNaN(Number(deckChildIdStr)) ? Number(deckChildIdStr) : 0

    useEffect(() => {
        const validateDeckChildId = () => {
            if (deckChildIdStr) {
                if (deckChildId <= 0 || !deckArchetype.getDeckChildById(deckChildId)) {
                    navigate(`/deckArchetypeView/${deckArchetype.id}`, { replace: true })
                }
            }
        }

        validateDeckChildId()
    }, [deckChildIdStr, deckChildId, navigate, deckArchetype])

    if (deckChildId === null || (deckChildIdStr && (isNaN(deckChildId) || deckChildId <= 0))) {
        return null
    }

    const selectedDeck = deckArchetype.getDeckChildById(deckChildId)

    return <DeckArchetypeDeckChildList deckArchetype={deckArchetype} selectedDeckChild={selectedDeck} />
}

const DeckArchetypeDeckChildList = ({ deckArchetype, selectedDeckChild }: { deckArchetype: DeckArchetype, selectedDeckChild?: DeckChild }) => {
    const navigate = useNavigate()
    const { type, format } = useParams()

    const [selectedCompareToDeckChild, setSelectedCompareToDeck] = useState<DeckChild | null>(null)

    const handleDeckSelected = (deckChild: DeckChild) => {
        setSelectedCompareToDeck(null)
        const newPath = deckChild ? `/deckArchetypeView/${format}/${type}/${deckArchetype.id}/${deckChild.id}` : `/deckArchetypeView/${type}/${deckArchetype.id}`
        navigate(newPath)
    }

    const handleCompare = (compareDeckChild: DeckChild) => {
        setSelectedCompareToDeck(compareDeckChild)
    }

    return (
        <>
            <Grid centered columns={1}>
                <Grid.Column>
                    <Header as='h1' textAlign="center">{deckArchetype.name}</Header>
                    <ArchetypeDeckChildListComponent decks={deckArchetype!.deckChilds} selectedDeck={selectedDeckChild} onCompare={handleCompare} onDeckSelected={handleDeckSelected} />
                </Grid.Column>
            </Grid>
            {selectedDeckChild ? <DeckArchetypeSelectedDeckChildComponent selectedDeckChild={selectedDeckChild} selectedCompareToDeckChild={selectedCompareToDeckChild} /> : null}
        </>
    )
}

const DeckArchetypeSelectedDeckChildComponent = ({ selectedDeckChild, selectedCompareToDeckChild }: { selectedDeckChild: DeckChild, selectedCompareToDeckChild: DeckChild | null }) => {
    const [isToggleOn, setIsToggleOn] = useState<boolean>(false)

    var cardComparisonChanges: { [key: string]: ComparisonResult } = {}
    const selectedDeckRef = useRef<HTMLDivElement>(null)

    const handleToggle = (isToggled: boolean) => {
        setIsToggleOn(isToggled)
        if (!isToggled) {
            cardComparisonChanges = {}
        } else {
            if (selectedCompareToDeckChild != null) {
                cardComparisonChanges = compareDecksToMap(selectedDeckChild.deckCardInfos, selectedCompareToDeckChild.deckCardInfos)
            }
        }
    }

    useEffect(() => {
        if (selectedDeckRef.current) {
            const topPosition = selectedDeckRef.current.getBoundingClientRect().top + window.pageYOffset;
            
            window.scrollTo({
                top: topPosition - 75,
                behavior: 'smooth'
            });
        }
    }, [selectedDeckChild])

    if (selectedCompareToDeckChild != null) {
        cardComparisonChanges = compareDecksToMap(selectedDeckChild.deckCardInfos, selectedCompareToDeckChild.deckCardInfos)
    }

    return (
        <>

            {selectedCompareToDeckChild != null &&
                <Grid >
                    <Grid.Column>
                        <ToggleSwitch onToggle={handleToggle} isToggled={isToggleOn} />
                    </Grid.Column>
                </Grid>
            }
            <Grid centered columns={selectedCompareToDeckChild != null ? 2 : 1} style={{ marginTop: '10px' }}>
                <Grid.Column>
                    <div ref={selectedDeckRef}>
                        <Deck
                            deckChild={selectedDeckChild}
                            comparisonList={isToggleOn ? cardComparisonChanges : null}
                            isSelected={selectedCompareToDeckChild != null ? 'selected' : 'no'}
                        />
                    </div>
                </Grid.Column>
                {selectedCompareToDeckChild != null &&
                    <Grid.Column>
                        <Deck
                            deckChild={selectedCompareToDeckChild}
                            comparisonList={isToggleOn ? cardComparisonChanges : null}
                            isSelected="comparedTo"
                        />
                    </Grid.Column>
                }
            </Grid>
        </>
    )
}

export interface ComparisonResult {
    id: number
    name: string
    status: 'added' | 'removed' | 'changed' | 'unchanged'
    selectedQuantity: number
    compareToQuantity: number
    defaultLocationId: number
}

function compareDecksToMap(selected: DeckCardInfo[], compareTo: DeckCardInfo[]): { [key: string]: ComparisonResult } {
    const comparisonResultsMap: { [key: string]: ComparisonResult } = {}

    // Create a map from the compareTo array
    const compareToMap = compareTo.reduce((acc: { [key: string]: DeckCardInfo }, card) => {
        acc[card.compositeKey] = card
        return acc
    }, {} as { [key: string]: DeckCardInfo })

    // Iterate through the selected array and compare with compareToMap
    selected.forEach(card => {
        if (compareToMap[card.compositeKey]) {
            const compareToCard = compareToMap[card.compositeKey]
            if (card.quantity !== compareToCard.quantity) {
                comparisonResultsMap[card.compositeKey] = {
                    id: card.id,
                    name: card.name,
                    status: 'changed',
                    selectedQuantity: card.quantity,
                    compareToQuantity: compareToCard.quantity,
                    defaultLocationId: card.defaultLocationId,
                }
            } else {
                comparisonResultsMap[card.compositeKey] = {
                    id: card.id,
                    name: card.name,
                    status: 'unchanged',
                    selectedQuantity: card.quantity,
                    compareToQuantity: card.quantity,
                    defaultLocationId: card.defaultLocationId,
                }
            }
            delete compareToMap[card.compositeKey] // Remove processed card from compareToMap
        } else {
            comparisonResultsMap[card.compositeKey] = {
                id: card.id,
                name: card.name,
                status: 'removed',
                selectedQuantity: card.quantity,
                compareToQuantity: 0,
                defaultLocationId: card.defaultLocationId,
            }
        }
    })

    // Add remaining cards in compareToMap to the comparisonResultsMap as 'added'
    Object.keys(compareToMap).forEach(compositeKey => {
        const card = compareToMap[compositeKey]
        comparisonResultsMap[compositeKey] = {
            id: card.id,
            name: card.name,
            status: 'added',
            selectedQuantity: -1,
            compareToQuantity: card.quantity,
            defaultLocationId: card.defaultLocationId,
        }
    })

    return comparisonResultsMap
}

export default DeckArchetypeViewUrlValidator
