import { Container, Dropdown, Menu } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import { useStore } from '../stores/store';
import { observer } from 'mobx-react-lite';

const NavBar = observer(() => {
    const { userStore: { isLoggedIn }} = useStore()

    return (
        <Menu inverted fixed='top'>
            <Container>
                <Menu.Item as={NavLink} to='/' header >
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center"}}>
                        <div style={{lineHeight: 1.2}}>Rule Zero</div>
                        <div style={{lineHeight: 1.2}}>Community</div>
                    </div>
                </Menu.Item>
                <Menu.Item as={NavLink} to='/' name='News' />
                <Menu.Item as={NavLink} to='/decklist/' name='Deck List' />
                <Menu.Item as={NavLink} to='/contentCreators' name='Content Creators' />
                {isLoggedIn && (
                    <>
                        <Menu.Item position='right'>
                            <Dropdown pointing='top left' text={"Create/Edit"}>
                                <Dropdown.Menu>
                                    <Dropdown.Item as={NavLink} to='/createAndEditDeck/' text='Deck' />
                                    <Dropdown.Item as={NavLink} to='/createAndEditContentCreator/' text='Content Creator' />
                                    <Dropdown.Item as={NavLink} to='/createAndEditArchetype/' text='Archetype' />
                                    <Dropdown.Item as={NavLink} to='/maintenance/' text='Maintenance' />
                                    <Dropdown.Item as={NavLink} to='/errors' text='Errors' />
                                </Dropdown.Menu>
                            </Dropdown>
                        </Menu.Item>
                    </>
                )}
            </Container>
        </Menu>
    )
})

export default NavBar

