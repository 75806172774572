import { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { Button, Grid, Header, Input, Loader, Segment } from "semantic-ui-react"
import { useNavigate, useParams } from "react-router-dom"
import { useStore } from "../../../app/stores/store"
import CacheService from "../../../CacheService"
import ContentInput from "../../../app/common/form/ContentInput"
import ContentCreator from "../../../app/models/contentCreator"

const CreateAndEditContentCreatorView = ({ contentCreator } : {contentCreator : ContentCreator}) => {
  const navigate = useNavigate()
  const { contentCreatorStore } = useStore()
  const { onContentCreatorChangeHandler } = contentCreatorStore

  const handleButtonClick = () => {
    const confirmed = window.confirm("Are you sure you want to delete it?")
    if (confirmed) {
      contentCreatorStore.delete()
    }
  }

  if (contentCreatorStore.loading) {
    return <Loader active inline="centered" />
  }

  const handleCreateOrUpdate = async ()=>{
    var contentCreator = await contentCreatorStore.createOrUpdate()
    navigate(`/createAndEditContentCreator/${contentCreator.id}`, { replace: true })
  }

  return (
    <>
      <Segment raised>
        <Header as='h3'>Content Creator Name</Header>
        <Grid>
          <Grid.Column width={12}>
            <Header as='h2'>
              <Input
                value={contentCreator.name}
                onChange={(e) => onContentCreatorChangeHandler("changeName", e.target.value)}
                fluid // Makes the input take the full width of the column
              />
            </Header>
          </Grid.Column>
          <Grid.Column width={4} textAlign='right'>
          {contentCreator.id !== 0 ? <Button primary onClick={handleButtonClick}>
              Delete
            </Button> : null
          }
            
          </Grid.Column>
        </Grid>
      </Segment>
      <Button primary onClick={handleCreateOrUpdate}>
        {contentCreator.id ? "Update" : "Create"} Content Creator
      </Button>
      {contentCreator.id !== 0 ?
        <>
          <Segment raised>
            <Header as='h3'>Social Media</Header>
            <Input label='Twitch'
              value={contentCreator.contentCreatorSocialMedias.find((socialMedia) => socialMedia.platformId === CacheService.getInstance().platformsByName["Twitch"])?.username || ""}
              onChange={(e) => onContentCreatorChangeHandler("socialMedia", { platformId: CacheService.getInstance().platformsByName["Twitch"], username: e.target.value })}
            />
            <Input label='YouTube'
              value={contentCreator.contentCreatorSocialMedias.find((socialMedia) => socialMedia.platformId === CacheService.getInstance().platformsByName["YouTube"])?.username || ""}
              onChange={(e) => onContentCreatorChangeHandler("socialMedia", { platformId: CacheService.getInstance().platformsByName["YouTube"], username: e.target.value })}
            />
            <Input label='Twitter'
              value={contentCreator.contentCreatorSocialMedias.find((socialMedia) => socialMedia.platformId === CacheService.getInstance().platformsByName["Twitter"])?.username || ""}
              onChange={(e) => onContentCreatorChangeHandler("socialMedia", { platformId: CacheService.getInstance().platformsByName["Twitter"], username: e.target.value })}
            />
          </Segment>
          <Segment raised>
            <Header as='h3'>Contents</Header>
            <ContentInput></ContentInput>
          </Segment>
        </>
        : null}
    </>
  )
}

const ContentCreatorViewUrlValidator = observer(() => {
  const navigate = useNavigate()
  const { contentCreatorId: contentCreatorIdStr } = useParams()
  const { contentCreatorStore } = useStore()
  const contentCreatorId = Number(contentCreatorIdStr)
    
  useEffect(() => {
    const validateDeckChildId = async () => {
      if (contentCreatorIdStr && isNaN(contentCreatorId)) {
        contentCreatorStore.empty()
        navigate('/createAndEditContentCreator', { replace: true })
      } else if (contentCreatorId && !isNaN(contentCreatorId) && contentCreatorStore.contentCreator.id === 0) {
        try {
          await contentCreatorStore.loadContentCreatorById(contentCreatorId)
        } catch (error) {
          console.error(error)
          navigate('/createAndEditContentCreator', { replace: true })
        }
      } 
    }
    validateDeckChildId()
  }, [contentCreatorIdStr, contentCreatorId, navigate, contentCreatorStore, contentCreatorStore.contentCreator])
  
  if (contentCreatorIdStr && isNaN(contentCreatorId)) {
    return null
  }

  return <CreateAndEditContentCreatorView contentCreator={contentCreatorStore.contentCreator}/>
})

export default ContentCreatorViewUrlValidator