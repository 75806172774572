import CommonStore from "./CommonStore";
import ModalStore from "./modalStore";
import UserStore from "./UserStore";
import ActivityStore from "./activityStore";
import { createContext, useContext } from "react";
import ProfileStore from "./profileStore";
import CommentStore from "./commentStore";
import DeckChildStore from "./deckChildStore";
import DeckArchetypeStore from "./deckArchetypeStore";
import CardStore from "./cardStore";
import ContentCreatorStore from "./contentCreatorStore";
import ContentStore from "./contentStore";
import NewsStore from "./newsStore";

export interface IStore {
    activityStore: ActivityStore
    commonStore: CommonStore
    userStore: UserStore
    modalStore: ModalStore
    profileStore: ProfileStore
    commentStore: CommentStore
    deckChildStore: DeckChildStore
    deckArchetypeStore: DeckArchetypeStore
    cardStore: CardStore
    contentCreatorStore: ContentCreatorStore
    contentStore: ContentStore
    newsStore : NewsStore
}

var cardStore = new CardStore()
export const store: IStore = {
    activityStore: new ActivityStore(),
    commonStore: new CommonStore(),
    userStore: new UserStore(),
    modalStore: new ModalStore(),
    profileStore: new ProfileStore(),
    commentStore: new CommentStore(),
    deckChildStore: new DeckChildStore(cardStore),
    deckArchetypeStore: new DeckArchetypeStore(),
    cardStore: cardStore,
    contentCreatorStore: new ContentCreatorStore(),
    contentStore: new ContentStore(),
    newsStore: new NewsStore(),
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext)
}