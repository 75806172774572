import { Grid, Checkbox } from 'semantic-ui-react';

interface CheckboxGridProps {
    options: { key?: number; text: string; value: number }[];
    selectedValues: number[];
    onCheckboxChange: (value: number) => void;
}

const CheckBoxGridFieldComponent = ({ options, selectedValues, onCheckboxChange } : CheckboxGridProps) => {
    return (
      <Grid columns={4} divided style={{minHeight: 130}}>
        {options.map((option) => (
          <Grid.Column key={option.key || option.value}> 
          <Checkbox
              label={option.text}
              value={option.value}
              checked={selectedValues.includes(option.value)}
              onChange={() => onCheckboxChange(option.value)}
            />
          </Grid.Column>
        ))}
      </Grid>
    );
};

export default CheckBoxGridFieldComponent;
