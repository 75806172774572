import agent from "./app/api/agent"
import ManaSymbol, { parseManaCost } from "./app/models/manaSymbol"

export default class CacheService {
    private static instance: CacheService
    private static initializing: Promise<void> | null = null
    dataFetched = false

    formats: {[key: number]: string} = {}
    formatsByName: {[key: string]: number} = {}
    tags: {[key: number]: string} = {}
    tagsByName: {[key: string]: number} = {}
    defaultLocations: {[key: number]: string} = {}
    defaultLocationsByName: {[key: string]: number} = {}
    platforms: {[key: number]: string} = {}
    platformsByName: {[key: string]: number} = {}
    colorCombinations : {[key: number]: {name : string, colors : ManaSymbol[]}} = {}
    deckLatestStates: {[key: number]: string} = {}
    contentTypes: {[key: number]: string} = {}

    static getInstance() {
        if (!this.instance) {
            this.instance = new CacheService()
        }
        return this.instance
    }

    static async initialize() {
        if (!CacheService.initializing) {
            if (!this.instance) {
                this.instance = new CacheService()
            }
            CacheService.initializing = this.instance.fetchData().finally(() => {
                CacheService.initializing = null
            })
        }
        await CacheService.initializing
    }

    async fetchData() {
        try {
            const storedData = localStorage.getItem('data')
            let dataToSet = null
    
            if (storedData) {
                const { formats, tags, defaultLocations, platforms, timestamp, colorCombinations, deckLatestStates, contentTypes } = JSON.parse(storedData)
                const now = new Date()
                const dataAge = now.getTime() - new Date(timestamp).getTime()
    
                if (dataAge < 10000) {
                    dataToSet = { formats, tags, defaultLocations, platforms, colorCombinations, deckLatestStates, contentTypes }
                }
            }
    
            if (!dataToSet) {
                const response = await agent.CachedData.getAllCachedData()
                if (response) {
                    dataToSet = response
    
                    localStorage.setItem('data', JSON.stringify({
                        ...response,
                        timestamp: new Date().toJSON()
                    }))
                }
            }
    
            if (dataToSet) {
                this.setValues(dataToSet)
            }

        } catch (error) {
            console.error("Failed to fetch data", error)
            const storedData = localStorage.getItem('data')
            if (storedData) {
                this.setValues(JSON.parse(storedData))      
            }
        }
    }

    private setValues(dataToSet: { formats: any; tags: any; defaultLocations: any; platforms: any; colorCombinations: any; deckLatestStates: any; contentTypes: any; }) {
        this.setValuesFromStoredData(dataToSet.formats, 'formats')
        this.setValuesFromStoredData(dataToSet.tags, 'tags')
        this.setValuesFromStoredData(dataToSet.defaultLocations, 'defaultLocations')
        this.setValuesFromStoredData(dataToSet.platforms, 'platforms')
        this.setValuesFromStoredData(dataToSet.colorCombinations, 'colorCombinations')
        this.setValuesFromStoredData(dataToSet.deckLatestStates, 'deckLatestStates')
        this.setValuesFromStoredData(dataToSet.contentTypes, 'contentTypes')
    }

    private setValuesFromStoredData(data: Object, property: keyof CacheService) {
       
        switch(property) {
            case 'formats':
                this.formats = data as {[key: number]: string}
                this.formatsByName = this.invertObject(this.formats)
                break
            case 'tags':
                this.tags = data as {[key: number]: string}
                this.tagsByName = this.invertObject(this.tags)
                break
            case 'defaultLocations':
                this.defaultLocations = data as {[key: number]: string}
                this.defaultLocationsByName = this.invertObject(this.defaultLocations)
                break
            case 'platforms':
                this.platforms = data as {[key: number]: string}
                this.platformsByName = this.invertObject(this.platforms)
                break
            case 'colorCombinations':
                var temp : {[key: number]: {name : string, colors : ManaSymbol[]}} = {}
                Object.entries(data).forEach(([key, value]) => {
                    temp[Number(key)] = {name : value.name, colors : parseManaCost(value.colors)}
                })
                this.colorCombinations = temp
                break
            case 'deckLatestStates':
                this.deckLatestStates = data as {[key: number]: string}
                break
            case 'contentTypes':
                this.contentTypes = data as {[key: number]: string}
                break
            default:
                throw new Error(`Invalid property name: ${property}`)
        }
    }

    invertMap(originalMap: Map<number, string>): Map<string, number> {
        const invertedMap = new Map<string, number>()
        originalMap.forEach((value, key) => {
            if (invertedMap.has(value)) {
                console.warn(`Duplicate key detected: ${value} from original key: ${key}. This will overwrite the existing key.`)
            }
            invertedMap.set(value, key)
        })
        return invertedMap
    }

    invertObject(originalObject : {[key: number]: string}) : {[key : string] : number} {
        let invertedObject : {[key : string] : number} = {}

        for (let key in originalObject) {
            if (originalObject.hasOwnProperty(key)) {
                invertedObject[originalObject[key]] = Number(key)
            }
        }
        return invertedObject
    }

    setDataFetched(){
        this.dataFetched = true
    }

    getDeckLatestStatesEntries(): [number, string][] {
        return Object.entries(this.deckLatestStates).map(
            ([key, value]) => [parseInt(key), value] as [number, string]
        )
    }

    getFormatsEntries(): [number, string][] {
        return Object.entries(this.formats).map(
            ([key, value]) => [parseInt(key), value] as [number, string]
        )
    }

    getTagsEntries(): [number, string][] {
        return Object.entries(this.tags).map(
            ([key, value]) => [parseInt(key), value] as [number, string]
        )
    }

    getDefaultLocationsEntries(): [number, string][] {
        return Object.entries(this.defaultLocations).map(
            ([key, value]) => [parseInt(key), value] as [number, string]
        )
    }

    getPlatformsEntries(): [number, string][] {
        return Object.entries(this.platforms).map(
            ([key, value]) => [parseInt(key), value] as [number, string]
        )
    }

    getColorCombinationsEntries(): [number, { name: string; colors: ManaSymbol[] }][] {
        return Object.entries(this.colorCombinations).map(
            ([key, value]) => [parseInt(key), value] as [number, { name: string; colors: ManaSymbol[] }]
        )
    }

}