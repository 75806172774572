import { SemanticCOLORS } from "semantic-ui-react";

export default interface Tag {
    id : number
    name : string
}


export function getColorFromTagName(tagName: string): SemanticCOLORS {
    const colors: SemanticCOLORS[] = ['red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple', 'pink', 'brown', 'grey', 'black'];
    let hash = 0;
    if(!tagName) return 'red';
    for (let i = 0; i < tagName.length; i++) {
      hash = tagName.charCodeAt(i) + ((hash << 5) - hash);
    }
    const index = Math.abs(hash % colors.length);
    return colors[index];
  };