import { PatchDeckChildRequestDto } from "../api/dto/deckChild/patchDeckChildRequestDto";
import { PostDeckChildRequestDto } from "../api/dto/deckChild/postDeckChildRequestDto";
import CardType from "./cardType";
import DeckCardInfo from "./deckCard";


export default interface DeckChild {
    id: number
    name: string
    deckCardInfos: DeckCardInfo[]
    formatId: number
    colorCombinationId: number
    deckLatestState: number
    type: string
    bestOfOne: boolean
    tagIds: number[]
    contents: ContentWithCreator[]
    createdDate : Date
    updatedDate : Date
    deckArchetypeName? : string
    deckArchetypeId? : number
}

export default class DeckChild {
    
    constructor(
        public id: number,
        public name: string,
        public formatId: number,
        public colorCombinationId: number,
        public deckLatestState: number,
        public bestOfOne: boolean,
        public tagIds: number[],
        public contents : ContentWithCreator[],
        public deckCardInfos: DeckCardInfo[],
        public createdDate : Date,
        public updatedDate : Date,
        public deckArchetypeName?: string,
        public deckArchetypeId?: number,
    ) {
    }

    static createEmptyObject(): DeckChild { return new DeckChild(0, '', 0, 0, 0, false, [], [], [], new Date(), new Date()); }   

    static createFromObject(obj: Partial<DeckChild>): DeckChild {
        return new DeckChild(
            obj.id ?? 0,
            obj.name ?? '',
            obj.formatId ?? 0,
            obj.colorCombinationId ?? 0,
            obj.deckLatestState ?? 0,
            obj.bestOfOne ?? false,
            obj.tagIds ?? [],
            obj.contents ?? [],
            obj.deckCardInfos ?? [],
            obj.createdDate ?? new Date(),
            obj.updatedDate ?? new Date(),
            obj.deckArchetypeName ?? '',
            obj.deckArchetypeId,
        );
    }

    static createFromAnyObject(input: any): DeckChild {
        return new DeckChild(
            input.id ?? 0,
            input.name ?? '',
            input.formatId ?? 0,
            input.colorCombinationId ?? 0,
            input.deckLatestState ?? 0,
            input.bestOfOne ?? false,
            input.tagIds ?? [],
            input.contents ?? [],
            Array.isArray(input.deckCardInfos) ? input.deckCardInfos.map((deckCardInfo: any) => new DeckCardInfo(
                deckCardInfo.id,
                deckCardInfo.cardNameId || deckCardInfo.name,
                deckCardInfo.defaultLocationId,
                deckCardInfo.quantity,
                deckCardInfo.types,
                deckCardInfo.manaCost
            )) : [],
            input.createdDate ? new Date(input.createdDate) : new Date(),
            input.updatedDate ? new Date(input.updatedDate) : new Date(),
            input.deckArchetypeName ?? "",
            input.deckArchetypeId ?? null
        );
    }
    
    convertToPostDeckChildRequestDto(): PostDeckChildRequestDto {
        const deckChildSaveDto: PostDeckChildRequestDto = {
            name: this.name,
            formatId: this.formatId,
            colorCombinationId: this.colorCombinationId,
            deckLatestState: this.deckLatestState,
            bestOfOne: this.bestOfOne,
            tagIds: this.tagIds,
            contentIds: this.contents.map(content => content.id),
            deckCards: this.deckCardInfos.map(deckCardInfo => {
                return {
                    cardNameId: deckCardInfo.name,
                    quantity: deckCardInfo.quantity,
                    defaultLocationId: deckCardInfo.defaultLocationId,
                }
            }),
            deckArchetypeId: this.deckArchetypeId,
            contentSourceId: this.contents.find(content => content.isSource)?.id
        };
        return deckChildSaveDto;
    }

    convertToPatchDeckChildRequestDto(): PatchDeckChildRequestDto {
        const deckChildSaveDto: PatchDeckChildRequestDto = {
            id : this.id,
            name: this.name,
            formatId: this.formatId,
            colorCombinationId: this.colorCombinationId,
            deckLatestState: this.deckLatestState,
            bestOfOne: this.bestOfOne,
            tagIds: this.tagIds,
            contentIds: this.contents.map(content => content.id),
            deckCards: this.deckCardInfos.map(deckCardInfo => {
                return {
                    id : deckCardInfo.id,
                    CardNameId: deckCardInfo.name,
                    quantity: deckCardInfo.quantity,
                    defaultLocationId: deckCardInfo.defaultLocationId,
                }
            }),
            deckArchetypeId: this.deckArchetypeId,
            contentSourceId: this.contents.find(content => content.isSource)?.id
        };
        return deckChildSaveDto;
    }
    
    findDeckCardByUniqueCompositeId(targetUniqueCompositeId: string): DeckCardInfo | undefined {
        return this.deckCardInfos.find(deckCardInfo => deckCardInfo.compositeKey === targetUniqueCompositeId);
    }

    returnCardByType = (cardTypesOrder = [CardType.Creature, CardType.Instant, CardType.Sorcery, CardType.Enchantment, CardType.Artifact, CardType.Land, CardType.Planeswalker, CardType.Tribal]): Map<CardType, DeckCardInfo[]> => {
        const newCardMapType = new Map<CardType, DeckCardInfo[]>();
        cardTypesOrder.forEach(type => newCardMapType.set(type, []));
        newCardMapType.set(CardType.Other, []);
    
        for (const deckCardInfo of this.deckCardInfos) {
            if (deckCardInfo.types !== undefined) {
                const foundType = cardTypesOrder.find(type => deckCardInfo.types?.includes(type)) ?? CardType.Other;
                newCardMapType.get(foundType)?.push(deckCardInfo);
            } else {
                
            }
        }
        return newCardMapType;
    }

    returnCardsByLocationAndType = (
        locations: number[],
        cardTypesOrder: CardType[] = [
            CardType.Creature,
            CardType.Instant,
            CardType.Sorcery,
            CardType.Enchantment,
            CardType.Artifact,
            CardType.Land,
            CardType.Planeswalker,
            CardType.Tribal,
            CardType.Other
        ]
    ): { [locationId: number]: { [cardType: string]: DeckCardInfo[] } } => {

        const locationTypeMap: { [locationId: number]: { [cardType: string]: DeckCardInfo[] } } = {};
        for (const locationId of locations) {
            locationTypeMap[locationId] = {};
            cardTypesOrder.forEach(cardType => {
                locationTypeMap[locationId][cardType] = [];
            });
            locationTypeMap[locationId]['Other'] = [];
        }
        
        for (const deckCardInfo of this.deckCardInfos) {
            if(locationTypeMap[deckCardInfo.defaultLocationId]){
                const foundType = deckCardInfo.types?.find(type => cardTypesOrder.includes(type)) ?? CardType.Other;
                locationTypeMap[deckCardInfo.defaultLocationId][foundType].push(deckCardInfo)
            }
        }

        for (const locationId in locationTypeMap) {
            const typeMap = locationTypeMap[locationId];
            for (const cardType in typeMap) {
                if (typeMap[cardType].length === 0) {
                    delete typeMap[cardType];
                }
            }
            if (Object.keys(typeMap).length === 0) {
                delete locationTypeMap[locationId];
            }
        }
        return locationTypeMap;
    };
    
    
}

interface ContentWithCreator {
    id: number;
    contentCreatorId: number;
    contentCreatorName: string;
    title: string;
    url: string;
    platformId: number;
    publishDate: Date;
    isSource: boolean;
}
