import { observer } from 'mobx-react-lite';
import { Segment, SegmentGroup } from "semantic-ui-react";
import { useStore } from '../../app/stores/store';
import NewsItem from './NewsItem';
import { News } from '../../app/models/news';

export default observer(function NewsList() {
    const { newsStore } = useStore();
    return (
        <>
            {Object.entries(newsStore.groupedNews).map(([key, newsList] : [string, News[]]) => (
                    <SegmentGroup key={key}>
                        <Segment secondary>
                            {key}
                        </Segment>
                        { newsList.map( news => <NewsItem news={news} key={news.id} />) }
                    </SegmentGroup>
            ))}
        </>
    )
})