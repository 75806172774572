import DeckCardInfo from "./deckCard"
import DeckChild from "./deckChild"
import Format from "./format"

export default interface DeckArchetype {
    id: number
    name: string
    deckChilds: DeckChild[]
    formats: Format[]
}

export default class DeckArchetype {
    constructor(public id: number, public name: string, public deckChilds: DeckChild[], public formatIds: number[]) {

    }
    static createFromDto(input: any): DeckArchetype {
        const deckChilds = Array.isArray(input.deckChilds) ? input.deckChilds.map((deckChild: any) => new DeckChild(
            deckChild.id ?? 0,
            deckChild.name ?? '',
            deckChild.formatId ?? 0,
            deckChild.colorCombinationId ?? 0,
            deckChild.deckLatestState ?? 0,
            deckChild.bestOfOne ?? false,
            deckChild.deckTagIds ?? [],
            deckChild.contents ?? [],
            Array.isArray(deckChild.deckCardInfos) ? deckChild.deckCardInfos.map((deckCard: any) => new DeckCardInfo(
                deckCard.id,
                deckCard.cardNameId,
                deckCard.defaultLocationId,
                deckCard.quantity,
                deckCard.type,
                deckCard.manaCost
            )) : [],
            deckChild.createdDate ? new Date(deckChild.createdDate) : new Date(),
            deckChild.updatedDate ? new Date(deckChild.updatedDate) : new Date(),
            input.name ?? '',
            input.id ?? 0
        )) : [];

        return new DeckArchetype(
            input.id ?? 0,
            input.name ?? '',
            deckChilds,
            input.formatIds ?? []
        );
    }

    static createEmpty() {
        return new DeckArchetype(0, '', [], []);
    }

    toSimpleObject() {
        return {
            id: this.id,
            name: this.name,
            deckChildIds: this.deckChilds.map(dc => dc.id),
            formatIds: this.formatIds
        };
    }

    getDeckChildById(id : number) : DeckChild | undefined {
        return this.deckChilds.find(deckChild => deckChild.id === id);
    }

}
