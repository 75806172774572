import { useState } from "react"
import { Grid, GridRow, GridColumn, TextArea, Button, Message } from "semantic-ui-react"
import DeckCardInfo from "../../../app/models/deckCard"
import agent from "../../../app/api/agent"

const DeckChildImportView = ({ importHandle }: { importHandle :  (deckCards: DeckCardInfo[]) => void }) => {

    const [textToImport, setTextToImport] = useState(`Companion\n\n\nDeck\n\n\nSideboard\n\n\n`)

    const [copied, setCopied] = useState(true)

    const parsedLines = parseTextToNumberAndPhrase(textToImport)
    const parsedDeck = parseTextToCards(parsedLines)

    const checkForCards = async ()=>{

        const cardToCheck: string[] = []
        var isGoodToImport = true
        for (const location in parsedDeck) {
            const cardsInLocation = parsedDeck[location]
            cardsInLocation.forEach(card => cardToCheck.push(card.name.toLocaleLowerCase()))
        }
        const result = await agent.Cards.CheckIfCardsExists(cardToCheck)

        var newTextToImport = ""
        for (const location in parsedDeck) {
            const cardsInLocation = parsedDeck[location]
            
            if(cardsInLocation.length > 0){
                const locationKey = location.toLowerCase()
                newTextToImport += `${locationKey} \n`
                for (const card of cardsInLocation) {

                    const didNotFindTheCard = result.includes(card.name.toLowerCase())
        
                    if (didNotFindTheCard) {
                        newTextToImport += "## NOT FOUND ## "
                    }
        
                    newTextToImport += `${card.quantity} ${card.name} \n`
                }
                newTextToImport += `\n`

            }
        }
        setCopied(isGoodToImport);
        setTextToImport(newTextToImport);
        
        if(isGoodToImport){
            const  deckCardInfos : DeckCardInfo[] = [];
            for (const location in parsedDeck) {
                const cardsInLocation = parsedDeck[location]
                cardsInLocation.forEach(card => deckCardInfos.push(DeckCardInfo.createFromAnyObject({...card})))
            }
            importHandle(deckCardInfos)
        }
    }
    
    return (
        <Grid>
            <GridRow>
                <GridColumn width={16} textAlign="right">
                    <Button onClick={()=>checkForCards()}>Check Import</Button>
                    {!copied && (
                        <Message
                            positive
                            compact
                            style={{
                                position: 'absolute',
                                top: '-40px',
                                right: '0',
                                zIndex: 10,
                                margin: '0'
                            }}
                        >
                            <Message.Header>Wrong Cards Fix it!</Message.Header>
                        </Message>
                    )}
                </GridColumn>
            </GridRow>
            <GridRow>
                <GridColumn width={16}>
                    <TextArea
                        value={textToImport}
                        placeholder="Enter text here"
                        style={{ width: '100%' }}
                        rows={30 > countLines(textToImport) ? 30 : countLines(textToImport) + 2}  // Sets the minimum number of lines (rows) to 5
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>)=> setTextToImport(e.target.value)}
                        />
                </GridColumn>
            </GridRow>
        </Grid>
    )
}

const defaultLocationsByName: {[key: string]: number} = {
    "deck": 1,
    "sideboard": 2,
    "companion": 3
}

function findKeyCaseInsensitive(obj: { [key: string]: number }, searchKey: string): [string, number] | undefined {
    const lowerCaseSearchKey = searchKey.toLowerCase()
    const foundKey = Object.keys(obj).find(key => key.toLowerCase() === lowerCaseSearchKey);

    return foundKey ? [foundKey, obj[foundKey]] : undefined
}

interface LocationGroup {
    [key: string]: ParsedCard[]
}

const parseTextToCards = (lines : ParsedLine[]) : LocationGroup  =>  {
    var currentLocation : [string, number] | undefined = undefined
    const parsedDeck: LocationGroup = {}

    for(var line of lines){
        if(line.number === undefined){
            var foundLocation = findKeyCaseInsensitive(defaultLocationsByName, line.phrase)
            if(foundLocation !== undefined){
                currentLocation = foundLocation
                if (!parsedDeck[currentLocation[0]]) {
                    parsedDeck[currentLocation[0]] = []
                }
                continue
            }   
        }
        
        if(currentLocation === undefined){
            continue
        }

        if(line.number !== undefined){
            parsedDeck[currentLocation[0]].push({
                quantity : line.number,
                name : line.phrase,
                defaultLocationId : currentLocation[1]
            })
        }
    }
    
    return parsedDeck
}



interface ParsedCard {
    quantity: number
    name: string
    defaultLocationId : number
}

interface ParsedLine {
    number?: number
    phrase: string
}


function countLines(text: string): number {
    if (text.length === 0) {
        return 0
    }
    return text.split('\n').length
}

const parseTextToNumberAndPhrase = (text: string): ParsedLine[] => {
    // Split the text into lines
    const lines = text.trim().split('\n')
    
    // Parse each line into an object with a number and a phrase
    var parsedLines = lines.map(line => {
        const parts = line.trim().split(' ')
        const firstPart = parts[0]
        
        // Check if the first part is a number
        if (!isNaN(Number(firstPart))) {
            return {
                number: Number(firstPart),  // Convert the first part to a number
                phrase: parts.slice(1).join(' ')  // Join the rest as the phrase
            }
        } else {
            return {
                phrase: line.trim()  // Treat the whole line as a phrase if there's no number
            }
        }
    })

    parsedLines = parsedLines.filter( item => item.phrase !== '' )

    return parsedLines
}

export default DeckChildImportView
