import DeckChild from "../../../app/models/deckChild";
import { Button, Grid, Header, Icon, Input, Segment, Table } from "semantic-ui-react";
import RenderManaCost from "../../../app/common/RenderManaCost";
import { DeckChangePredicate } from "../../../app/stores/deckChildStore";


    
const DeckChildCardsEditFieldComponent = ({ deck, deckChangeHandler: cardChangeHandler }: { deck: DeckChild, deckChangeHandler: (predicate: DeckChangePredicate, change?: any) => void; }) => {

  const renderDeckCards = (defaultLocationId? : number)=>
      deck.deckCardInfos
      .filter(deckCardInfo => defaultLocationId === undefined || deckCardInfo.defaultLocationId === defaultLocationId)
      .map( deckCardInfo => {
        return (
          <Table.Row key={deckCardInfo.compositeKey}>
            <Table.Cell style={{ padding: '1px', border: 'none' }}>
              <div style={{ padding: '1px', border: 'none' }}>
                <Input
                  type="number"
                  value={deckCardInfo.quantity}
                  style={{ width: '5em' }}
  
                  onChange={(e) => {
                    const inputValue = parseInt(e.target.value, 10);
                    const validValue = isNaN(inputValue) || inputValue <= 0 ? 1 : inputValue;
                    cardChangeHandler('quantityChange', { value: validValue, uniqueId: deckCardInfo.compositeKey });
                  }}
                />
                <Button
                  icon
                  size="tiny"
                  color="red"
                  onClick={() => cardChangeHandler('remove', deckCardInfo.compositeKey)}
                >
                  <Icon name="x" />
                </Button>
                <span style={{ marginLeft: '5px' }}>{deckCardInfo.name}</span>
              </div>
            </Table.Cell>
            <Table.Cell style={{ padding: '1px', border: 'none' }}>
              <span className="deck-list-entry-mana-cost">{RenderManaCost({colors : deckCardInfo.manaCost})}</span>
            </Table.Cell>
          </Table.Row>
        );
      });

    const cardQuantity = (defaultLocationId? : number)=>{
      return deck.deckCardInfos
      .filter(deckCardInfo => defaultLocationId === undefined || deckCardInfo.defaultLocationId === defaultLocationId)
      .reduce((a, b)=> {
        return b.quantity + a
      }, 0)
    }

  return (
    <Grid>
      <Grid.Column width={10}>
        <Segment>
          <Header as='h3'>Main ({cardQuantity(1)})</Header>
          <Table celled striped style={{ border: 'none' }}>
            <Table.Body>
              {renderDeckCards(1)}
            </Table.Body>
          </Table>
        </Segment>
      </Grid.Column>

      <Grid.Column width={6}>
        <Segment>
          <Header as='h3'>Sideboard ({cardQuantity(2)})</Header>
          <Table celled striped style={{ border: 'none' }}>
            <Table.Body>
              {renderDeckCards(2)}
            </Table.Body>
          </Table>
        </Segment>
        <Segment>
          <Header as='h3'>Maybeboard ({cardQuantity(6)})</Header>
          <Table celled striped style={{ border: 'none' }}>
            <Table.Body>
              {renderDeckCards(6)}
            </Table.Body>
          </Table>
        </Segment>
      </Grid.Column>
    </Grid>
  );
}

export default DeckChildCardsEditFieldComponent