import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { useEffect } from "react";
import { Card, Container, Loader, Segment, Label } from "semantic-ui-react";
import { Link } from "react-router-dom";
import CacheService from "../../../CacheService";

const ContentCreatorsListView = () => {
    const { contentCreatorStore } = useStore();

    useEffect(() => {
        contentCreatorStore.loadAllContentCreators();
    }, [contentCreatorStore]);

    if (contentCreatorStore.loadingList) {
        return <Loader active inline="centered" />;
    }

    const getPlatformUrl = (platformId: number, username: string) => {
        const platform = CacheService.getInstance().platforms[platformId].toLowerCase();
        return `https://${platform}.com/${username}`;
    };

    return (
        <Segment>
            <Container>
                <Card.Group itemsPerRow={1}>
                    {contentCreatorStore.contentCreators.map(contentCreator => (
                        <Card key={contentCreator.id}>
                            <Card.Content>
                                <Card.Header>
                                    <Link to={`/contentCreator/${contentCreator.id}`}>{contentCreator.name}</Link>
                                </Card.Header>
                                <Card.Meta>
                                    <span className='date'>{ }</span>
                                </Card.Meta>
                                <Card.Description>
                                    
                                </Card.Description>
                            </Card.Content>
                            <Card.Content extra>
                                {contentCreator.contentCreatorSocialMedias.map((socialMedia, index) => (
                                    <Label key={index} image style={{ marginLeft: '5px', minWidth: "100px" }}>
                                        <i className={`${CacheService.getInstance().platforms[socialMedia.platformId].toLowerCase()} icon`} />
                                        <a href={getPlatformUrl(socialMedia.platformId, socialMedia.username)} target="_blank" rel="noopener noreferrer">
                                            {socialMedia.username}
                                        </a>
                                    </Label>
                                ))}
                            </Card.Content>
                        </Card>
                    ))}
                </Card.Group>
            </Container>
        </Segment>
    );
};

export default observer(ContentCreatorsListView);
