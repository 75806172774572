import CardType from "./cardType"
import ManaSymbol, { parseManaCost } from "./manaSymbol"

export default class DeckCardInfo {
    id : number
    name: string
    defaultLocationId : number
    quantity: number
    compositeKey : string
    types : CardType[]
    manaCost : ManaSymbol[]

    constructor
    (
        id: number, 
        name: string, 
        defaultLocationId: number, 
        quantity: number, 
        types: CardType[] | string,  
        manaCost: ManaSymbol[] | string
    ) 
    {
        this.id = id
        this.name = name
        this.defaultLocationId = defaultLocationId
        this.quantity = quantity
        this.types = typeof types === 'string' ? DeckCardInfo.findCardTypes(types) : types
        this.manaCost = typeof manaCost === 'string' ? this.setManaCost(manaCost) : manaCost
        this.compositeKey = this.generateCompositeUniqueId()
    }

    static createFromAnyObject(input: any): DeckCardInfo {
        return new DeckCardInfo(
            input.id ?? 0,
            (input.cardNameId || input.nameId || input.name) ?? "",
            input.defaultLocationId ?? 0,
            input.quantity ?? 0,
            typeof input.types === 'string' ? DeckCardInfo.findCardTypes(input.types) : input.types ?? [],
            typeof input.manaCost === 'string' ? parseManaCost(input.manaCost) : input.manaCost ?? [],
        );
    }

    static createEmpty(): DeckCardInfo {      
        return new DeckCardInfo( 0,"", 0, 0, '', '');
    }

    static findCardTypes(input: string): CardType[]{
        const cardTypes: CardType[] = [];
        const types: string[] = input.split(" ");
        types.forEach(type => {
            const trimmedType = type.trim().toLowerCase();

            if (Object.values(CardType).map(value => value.toLowerCase()).includes(trimmedType)) {
                const originalEnumValue = Object.keys(CardType).find(key => (CardType as { [index: string]: any })[key].toLowerCase() === trimmedType);
    
                if (originalEnumValue) {
                    const cardType = CardType[originalEnumValue as keyof typeof CardType];
                    if (!cardTypes.includes(cardType)) {
                        cardTypes.push(cardType);
                    }
                }
            }
        });
        return cardTypes;
    }

    getSplitNames(): string[] {
        return this.name.split(' // ');
    }
   
    private generateCompositeUniqueId(): string {
        return `${this.name}_${this.defaultLocationId}`;
    }

    public setManaCost(manaCost: string): ManaSymbol[] {
        return this.manaCost = parseManaCost(manaCost)
    }


}