import { useEffect } from 'react'
import { Grid } from 'semantic-ui-react'
import { useStore } from '../../../app/stores/store'
import LoadingComponents from '../../../app/layout/LoadingComponent'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router-dom'
import ActivityDetailedChat from './ActivityDetailedChat'
import ActivityDetailedHeader from './ActivityDetailedHeader'
import ActivityDetailedInfo from './ActivityDetailedInfo'
import ActivityDetailedSidebar from './ActivityDetailedSidebar'

const ActivityDetails = observer(()=>
{
    const { activityStore } = useStore()
    const { selectedActivity: activity, loadActivity, loadingInitial } = activityStore
    const {id} = useParams()

    useEffect(()=>{
        if(id) loadActivity(id)
    }, [id, loadActivity])
    
    if(loadingInitial || !activity) return <LoadingComponents />
    
    return (
        <Grid>
            <Grid.Column width={10}>
                <ActivityDetailedHeader activity={activity}/>
                <ActivityDetailedInfo activity={activity}/>
                <ActivityDetailedChat activityId={activity.id}/>
            </Grid.Column>
            <Grid.Column width={6}>
                <ActivityDetailedSidebar activity={activity}/>
            </Grid.Column>
        </Grid>
    )
})

export default ActivityDetails
