import ManaSymbol from '../models/manaSymbol';
import CacheService from '../../CacheService';

const RenderManaCost = ( { colors, size = 1 } : {colors : number | ManaSymbol[], size? : number} ) => {

    let values: ManaSymbol[];
    if (typeof colors === 'number') {
        try {
            values = CacheService.getInstance().colorCombinations[colors].colors;
        }catch {
            values = []
        }
        
    } else {
        values = colors;
    }

    if (values.length === 0) {
        return <></>;
    }

    return (
        <>
            {
                values.map((value: ManaSymbol, index: number) => {
                    var style = value.style ? {width : value.style.width * size, height: value.style.height * size } : {width : 15 * size, height: 15 * size }
                    return <span key={index}><abbr title={value.title} style={style} className={`card-symbol card-symbol-${value.class}`}>{value.class}</abbr></span>
                })
            }
        </>
    )
}

export default RenderManaCost
