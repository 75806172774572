
const YouTubeVideoComponent = ({ videoId }: { videoId: string }) => {
  const embedUrl = `https://www.youtube.com/embed/${videoId}`;

  return (
    <div style={{
      display: 'flex',         // Enables Flexbox layout
      justifyContent: 'center', // Centers content horizontally in the flex container
      alignItems: 'center',    // Centers content vertically in the flex container
    }}>
      <iframe
        width="560"
        height="315"
        src={embedUrl}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded YouTube Video"
      ></iframe>
    </div>
  );
};

export default YouTubeVideoComponent;
