import { Tab } from 'semantic-ui-react'
import ProfilePhoto from './ProfilePhotos'
import { Profile } from '../../app/models/profile'
import { observer } from 'mobx-react-lite'
import ProfileFollowings from './ProfileFollowings'
import { useStore } from '../../app/stores/store'
import ProfileActivities from './ProfileActivities'

interface Props {
    profile: Profile
}

export default observer(function ProfileContent({profile} : Props) {
    const {profileStore} = useStore()

    const panes = [
        {menuItem: 'About', render: ()=> <Tab.Pane>About Content</Tab.Pane>},
        {menuItem: 'Photos', render: ()=> <ProfilePhoto profile={profile}/>},
        {menuItem: 'Events', render: ()=> <Tab.Pane><ProfileActivities/></Tab.Pane>}, 
        {menuItem: 'Followers', render: ()=> <Tab.Pane><ProfileFollowings/></Tab.Pane>},
        {menuItem: 'Following', render: ()=> <Tab.Pane><ProfileFollowings/></Tab.Pane>},
    ]

    return (
        <Tab 
            menu={{fluid: true, vertical: true}}
            menuPosition='right'
            panes={panes}
            onTabChange={(e, data) => profileStore.setActiveTab(data.activeIndex)}
        />
    )
})