import { useField, useFormikContext } from 'formik';
import { Form, Checkbox, Label } from 'semantic-ui-react';

interface CheckboxOption {
    label: string;
    value: number;
    key?: any;
}

interface Props {
    name: string;
    options: CheckboxOption[];
    label?: string;
}

export default function MyCheckboxGroup({ name, options, label }: Props) {
    const [field, meta, helpers] = useField(name);
    const { isSubmitting } = useFormikContext(); // Access formik context to get isSubmitting


    const handleChange = (value: number) => {
        const set = new Set(field.value);
        if (set.has(value)) {
            set.delete(value);
        } else {
            set.add(value);
        }
        helpers.setValue(Array.from(set));
    };
   
    return (
        <Form.Field error={meta.touched && !!meta.error}>
            {label && <label>{label}</label>}
            {options.map(option => (
                <div key={option.key || option.value}> {}
                    <Checkbox
                        label={option.label}
                        value={option.value}
                        checked={field.value.includes(option.value)}
                        onChange={() => handleChange(option.value)}
                        disabled={isSubmitting}
                    />
                </div>
            ))}
            {meta.touched && meta.error ? (
                <Label basic color='red'>{meta.error}</Label>
            ) : null}
        </Form.Field>
    );
}
