import { useCallback, useEffect } from "react"
import { observer } from "mobx-react-lite"
import AutocompleteField from "./CardAutoCompleteFieldComponent"
import DecksCardEditView from "./DeckChildCardsEditFieldComponent"
import { Button, Checkbox, Dropdown, Grid, GridColumn, GridRow, Header, Input, Loader, Segment } from "semantic-ui-react"
import CheckBoxGridFieldComponent from "../CreateAndEditContentCreator/CheckBoxGridFieldComponent"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useStore } from "../../../app/stores/store"
import CacheService from "../../../CacheService"
import AddContentToDeckChildField from "./AddContentToDeckChildFieldComponent"
import AddColorCombinationFieldComponent from "./AddColorCombinationFieldComponent"
import DeckChildImportView from "./DeckChildImportView"
import DeckCardInfo from "../../../app/models/deckCard"

const CreateAndEditDeckView = observer(() => {
  const navigate = useNavigate()
  const { deckChildStore: deckStore, deckArchetypeStore, contentStore, modalStore } = useStore()
  const { deckChild } = deckStore

  const fetchDeckArchetypeOptions = useCallback(async () => {
    if (deckStore.deckChild.formatId) {
      await deckArchetypeStore.getByFormatId(deckStore.deckChild.formatId)
    }
  }, [deckStore.deckChild.formatId, deckArchetypeStore])

  const createDeckOrUpdate = useCallback(async () => {
    try {
      await deckStore.createDeckOrUpdate()
      navigate(`/createAndEditDeck/${deckStore.deckChild.id}`, { replace: true })
    } catch (error) {
      console.error(error)
    }
  }, [deckStore, navigate])

  const deleteDeckChild = useCallback(async () => {
    const confirmation = window.confirm("Are you sure you want to delete this deck?")
    if (!confirmation) {
      return
    }
    try {
      const deleted = await deckStore.deleteDeckChildById()
      if (deleted) {
        navigate(`/createAndEditDeck/`, { replace: true })
      }

    } catch (error) {
      console.error(error)
    }
  }, [deckStore, navigate])

  useEffect(() => {
    contentStore.requestContentWithoutDeckChildList(-1, deckChild.id)
  }, [contentStore, deckChild.id])

  useEffect(() => {
    fetchDeckArchetypeOptions()
  }, [fetchDeckArchetypeOptions])

  const importHandle = ((deckCards : DeckCardInfo[])=>{
    deckStore.onDeckChangeHandler("addFromImport", deckCards)
  })

  if (deckStore.loading) {
    return <Loader active inline="centered" />
  }

  return (
    <>
      <Button onClick={createDeckOrUpdate} primary>
        Upload Deck
      </Button>
      <Button onClick={deleteDeckChild} primary>
        Delete Deck
      </Button>
      <Button onClick={()=> navigate('/createAndEditDeck')} primary>
        New Deck
      </Button>
      {deckChild.id > 0 ? 
      <Link to={`/deck/${deckChild.id}`} >
        See Deck
      </Link> : null}
      <Segment raised>
        <Header as='h3'>Deck Name</Header>
        <Header as='h2'>
          <Input
            value={deckChild.name}
            onChange={(e) => deckStore.onDeckChangeHandler("changeName", e.target.value)}
          />
        </Header>
      </Segment>

      <Segment raised>
        <Header as='h3'>Deck Format and Archetype</Header>
        <span style={{ marginRight: '1em' }}>
          <Dropdown
            placeholder='Select Format'
            selection
            options={Object.entries(CacheService.getInstance().formats).map(([id, name]) => ({ key: id, text: name, value: id }))}
            value={deckChild.formatId + ''}
            onChange={(e, { value }) => deckStore.onDeckChangeHandler("changeFormat", value)}
          />
        </span>
        <Dropdown
          placeholder={deckArchetypeStore.loadingList ? 'Loading Options...' : `Select Deck Archetype (${deckArchetypeStore.deckArchetypes.length} options)`}
          selection
          options={[
            { key: 'undefined', text: 'No Archetype', value: undefined },
            ...deckArchetypeStore.deckArchetypes.map((archetype) => ({
              key: archetype.id,
              text: archetype.name,
              value: archetype.id,
            }))
          ]}
          value={deckChild.deckArchetypeId}
          onChange={(e, { value }) => deckStore.onDeckChangeHandler("changeDeckArchetype", value)}
        />
      </Segment>
      <Grid>
        <GridColumn width={8}>
          <GridRow>
            <Segment>
              <Header as='h3'>BO3 or BO1</Header>
              <Checkbox
                label="is Best of one?"
                checked={deckChild.bestOfOne}
                onChange={(_, value) => { deckStore.onDeckChangeHandler("isBO1", value.checked) }}
              />
            </Segment>
          </GridRow>
        </GridColumn>
        <GridColumn width={8}>
          <GridRow>
            <AddColorCombinationFieldComponent deckStore={deckStore} />
          </GridRow>
        </GridColumn>
      </Grid>
      <Segment raised>
        <Header as='h3'>Set Deck Latest State</Header>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Dropdown
                options={
                  CacheService.getInstance().getDeckLatestStatesEntries().map(([key, value]) => {
                    return {
                      key: key,
                      text: value,
                      value: key,
                    }
                  })
                }
                value={deckChild.deckLatestState}
                onChange={(e, { value }) => deckStore.onDeckChangeHandler("deckLatestState", value)}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <Segment raised>
        <Header as='h3'>Add Cards To Deck</Header>
        <Grid>
          <GridColumn width={12}>
            <GridRow>
              <AutocompleteField handler={(change) => deckStore.onDeckChangeHandler("addCard", change)} />
            </GridRow>
          </GridColumn>
          <GridColumn width={4}>
            <GridRow>
              <Button primary onClick={() => modalStore.openModal(<DeckChildImportView importHandle={importHandle} />)}>Export</Button>
            </GridRow>
          </GridColumn>
        </Grid>
      </Segment>
      <Segment raised>
        <DecksCardEditView deck={deckChild} deckChangeHandler={deckStore.onDeckChangeHandler} />
      </Segment>
      <Segment raised>
        <Header as='h3'>Deck Tags</Header>
        <CheckBoxGridFieldComponent
          options={Object.entries(CacheService.getInstance().tags).map(([id, name]) => ({ text: name, value: +id }))}
          selectedValues={deckChild.tagIds}
          onCheckboxChange={deckStore.handleCheckboxChange}
        />
      </Segment>
      <AddContentToDeckChildField contentStore={contentStore} deckStore={deckStore} modalStore={modalStore} />
    </>
  )
})

const DeckCreateUrlValidator = () => {
  const navigate = useNavigate()
  const { deckChildId: deckChildIdStr } = useParams()
  const { deckChildStore: deckStore } = useStore()
  const deckChildId = Number(deckChildIdStr)

  useEffect(() => {
    const validateDeckChildId = async () => {
      if (deckChildIdStr && isNaN(deckChildId)) {
        navigate('/createAndEditDeck', { replace: true })
      } else if (deckChildId && !isNaN(deckChildId)) {
        try {
          await deckStore.loadDeckChildById(deckChildId)
        } catch (error) {
          console.error(error)
          navigate('/createAndEditDeck', { replace: true })
        }
      } else {
        deckStore.emptyTheDeck()
      }
    }

    validateDeckChildId()
  }, [deckChildIdStr, deckChildId, navigate, deckStore])

  if (deckChildIdStr && isNaN(deckChildId)) {
    return null
  }

  return <CreateAndEditDeckView />
}


export default DeckCreateUrlValidator
