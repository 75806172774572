import { Fragment, useEffect, useState } from 'react'
import { observer } from "mobx-react-lite";
import { Grid, Image, Segment, Table } from 'semantic-ui-react';
import DeckChild from '../../../app/models/deckChild';
import DeckCardInfo from '../../../app/models/deckCard';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import CacheService from '../../../CacheService';
import RenderManaCost from '../../../app/common/RenderManaCost';
import { useNavigate, useParams } from 'react-router-dom';
import { useStore } from '../../../app/stores/store';
import YouTubeVideoComponent from '../DeckArchetypeView/YoutubeVideoComponent';

function cardTypeQuantity(cardList: DeckCardInfo[] = []) {
    return cardList.reduce((sum, current) => sum + current.quantity, 0)
}

const DeckChildViewUrlValidator = observer(() => {
    const navigate = useNavigate();
    const { deckChildId: deckChildIdStr } = useParams<{ deckChildId: string }>();
  
    const { deckChildStore } = useStore();
    const deckChildId = deckChildIdStr && !isNaN(Number(deckChildIdStr)) ? Number(deckChildIdStr) > 0 ? Number(deckChildIdStr) : null : null;
    useEffect(() => {
      const validateAndLoadDeckArchetype = async () => {
        if (deckChildId === null) {
          navigate('/deckList', { replace: true });
          return;
        }
        try {
          await deckChildStore.loadDeckChildById(deckChildId);
        } catch (error) {
          console.error('Failed to load deck archetype:', error);
          navigate('/deckList', { replace: true });
        }
      };
  
      validateAndLoadDeckArchetype();
    }, [deckChildId, deckChildStore, navigate])

    if(deckChildId === null) return null
    if(deckChildStore.loadingInitial) return null
    if(!deckChildStore.deckChild ) return null
    if(deckChildStore.deckChild.id === 0) return null
    if(deckChildStore.deckChild.id !== deckChildId) return null
    return <DeckChildNormalListComponent deckChild={deckChildStore.deckChild} />;
  })

const DeckChildNormalListComponent = ({ deckChild }: { deckChild: DeckChild }) => {
    const [hoveredCard, setHoveredCard] = useState<string | null>(null);
    const [imageHoverPosition, setImageHoverPosition] = useState<{}>({});

    const handleMouseEnter = (cardIdentifier: string, event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        const cardRect = event.currentTarget.getBoundingClientRect()
        const viewportHeight = window.innerHeight
        const spaceBelow = viewportHeight - cardRect.bottom
        const imageHeight = 200

        let imagePosition: {};
        if (spaceBelow < imageHeight && cardRect.top > imageHeight) {
            imagePosition = {
                position: 'fixed',
                bottom: viewportHeight - cardRect.top + 5,
                left: cardRect.left + 50,
            }
        } else {
            imagePosition = {
                position: 'fixed',
                top: cardRect.bottom + 5,
                left: cardRect.left + 50,
            };
        }

        setImageHoverPosition(imagePosition)
        setHoveredCard(cardIdentifier)
    };

    function ShowCardPictures(cardName : string) {
        return <div style={{
            position: 'fixed',
            zIndex: 10,
            ...imageHoverPosition,
        }}>
            <Image src={`https://rulezeromagiccards.s3.eu-north-1.amazonaws.com/magic/${fixedName(cardName)}`} size="tiny" wrapped ui={false} width={200} />
        </div>;
    }

    function fixedName(cardName: string): string {
        
        let fixedName = cardName
                        .trim()
                        .replace(/ /g, '_')
                        .replace('//', '')
                        .replace(/[^\w\-.]/g, '')

        return fixedName;
    }

    const renderCardsByType = (cards: DeckCardInfo[], type: string) => (
        <Fragment key={type}>
            <Table.Row>
                <Table.Cell colSpan={3} style={{ padding: '1px', border: 'none', textTransform: 'capitalize' as 'capitalize', paddingLeft: 3, fontWeight: "bold" }}>
                    {type} ({cardTypeQuantity(cards)})
                </Table.Cell>
            </Table.Row>
            {cards.map((card) => {
                return (
                    <Table.Row key={card.compositeKey}
                        onMouseEnter={(e: any) => handleMouseEnter(card.compositeKey, e)}
                        onMouseLeave={() => setHoveredCard(null)}
                    >
                        <Table.Cell style={{fontSize: "16px", padding: '1px 1px 1px 10px', border: 'none' }}>
                            <span>
                                {card.quantity} {card.name}
                            </span>
                            {hoveredCard === card.compositeKey && (
                                ShowCardPictures(card.name)
                            )}
                        </Table.Cell >
                        <Table.Cell style={{ padding: '1px', border: 'none' }}>
                            <span className="deck-list-entry-mana-cost">
                                {RenderManaCost({ colors: card.manaCost })}
                            </span>
                        </Table.Cell>
                    </Table.Row>
                );


            })}
        </Fragment>
    );

    return (
        <>
            <Segment raised>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <h1>{deckChild.name}</h1>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <div>
                                <Table celled striped>
                                    <Table.Body>
                                        {
                                            Object.entries(deckChild.returnCardsByLocationAndType([1, 2])).map(([locationId, typesMap]) => (
                                                <Fragment key={locationId}>
                                                    <Table.Row>
                                                        <Table.Cell colSpan={3} style={{ padding: '1px', border: 'none', fontSize: "16px", fontWeight: "bolder" }}>
                                                            {CacheService.getInstance().defaultLocations[Number(locationId)] || 'Unknown Location'}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                    {Object.entries(typesMap).map(([type, cards]) => (
                                                        cards.length > 0 && renderCardsByType(cards, type)
                                                    ))}
                                                </Fragment>
                                            ))
                                        }
                                    </Table.Body>
                                </Table>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            <Segment raised>
                <Grid.Row>
                    <Grid.Column>
                        <h1>Contents</h1>
                    </Grid.Column>
                </Grid.Row>
                {deckChild.contents && deckChild.contents.length > 0 ?
                    deckChild.contents.map(content =>
                        <Grid key={content.id} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Grid.Row>
                                <Grid.Column textAlign='center'>
                                    {CacheService.getInstance().platforms[content.platformId] === "YouTube" ? <YouTubeVideoComponent videoId={content.url} /> : null}
                                    {CacheService.getInstance().platforms[content.platformId] === "Twitter" ? <TwitterTweetEmbed options={{ height: 400, width: 1000 }} tweetId={content.url} /> : null}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    ) : "No Content Yet. Go Make Some!"
                }
            </Segment>
        </>
    );
}

export default  DeckChildViewUrlValidator