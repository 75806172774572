import { useEffect } from 'react';
import { Button, Loader } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, Form} from 'formik';
import MyTextInput from '../../../app/common/form/MyTextInput';
import MyCheckboxGroup from '../../../app/common/form/MyCheckboxGroup';
import { useStore } from '../../../app/stores/store';
import { observer } from 'mobx-react-lite';
import CacheService from '../../../CacheService';

const CreateDeckArchetype = observer(() =>{
  const navigate = useNavigate()
    const { deckArchetypeStore } = useStore()

  if(deckArchetypeStore.loading){
    return <Loader active inline="centered" />
  }
  
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={deckArchetypeStore.deckArchetype}
        validationSchema={deckArchetypeStore.deckArchetypeCreateDtoSchema}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await deckArchetypeStore.deckArchetypeCreateDtoSchema.validate(values, { abortEarly: false })
            if(deckArchetypeStore.deckArchetype.id){
              deckArchetypeStore.updateDeckArchetype(values)

            } else {
              const deckarchetype = await deckArchetypeStore.createDeckArchetype(values)
              navigate(`/createAndEditArchetype/${deckarchetype.id}`, {replace : true});
            }
          } catch (error) {
            console.error(error) 
          } finally {
            setSubmitting(false)
          }
        }}
      >
        {() => (
          <Form className='ui form'>
            {deckArchetypeStore.submitting && <Loader active inline="centered" />}
            <div>
              <label>Archetype Name:</label>
              <MyTextInput name='name' placeholder='Enter the archetype name' />
            </div>
            <div>
              <MyCheckboxGroup
                name= "formatIds"
                options={Object.entries(CacheService.getInstance().formats).map(([id, name]) => ({ label: name, value: +id }))}
                label="this is the label"
              />
            </div>
            <Button type="submit" primary disabled={deckArchetypeStore.submitting} active={deckArchetypeStore.submitting}>
              {deckArchetypeStore.deckArchetype.id !== 0 ? "Edit" : "Create"} Archetype
            </Button>
          </Form>
        )}
    </Formik>
  </>
  );
});


const CreateDeckArchetypeViewUrlValidator = () => {
  const navigate = useNavigate()
  const { deckArchetypeId: deckArchetypeIdStr } = useParams()

  const { deckArchetypeStore } = useStore()
  const deckArchetypeId = Number(deckArchetypeIdStr)

  useEffect(() => {
    const validateDeckArchetypeId = async () => {
      if (deckArchetypeIdStr && isNaN(deckArchetypeId)) {
        navigate('/createAndEditDeck', { replace: true })
      } else if (deckArchetypeId && !isNaN(deckArchetypeId)) {
        try {
          deckArchetypeStore.loadDeckArchetypeFlatById(deckArchetypeId)
        } catch (error) {
          console.error(error);
          navigate('/createAndEditArchetype', { replace: true })
        }
      } else {
        deckArchetypeStore.setDeckArchetypeEmpty()
      }
    }

    validateDeckArchetypeId();
  }, [deckArchetypeIdStr, deckArchetypeId, navigate, deckArchetypeStore]);

  if (deckArchetypeIdStr && isNaN(deckArchetypeId)) {
    return null
  }

  return <CreateDeckArchetype />
}


export default CreateDeckArchetypeViewUrlValidator