export default interface ManaSymbol {
    title: string;
    class: string;
    style?: { width: number, height: number };
}

export const manaValuesMap = new Map<string, ManaSymbol>(
    [
        ["2/B", {title: "two mana or one black mana", class:"2B"}],
        ["2/G", {title: "two mana or one green mana", class:"2G"}],
        ["2/R", {title: "two mana or one red mana", class:"2R"}],
        ["2/U", {title: "two mana or one blue mana", class:"2U"}],
        ["2/W", {title: "two mana or one white mana", class:"2W"}],
        ["X", {title: "variable mana cost", class:"X"}],
        ["C", {title: "colorless mana", class:"C"}],
        ["S", {title: "snow mana", class:"S"}],
        // Hybrid mana
        ["B/G", {title: "black or green mana", class:"BG"  }],
        ["B/R", {title: "black or red mana", class:"BR"    }],
        ["G/U", {title: "green or blue mana", class:"GU"   }],
        ["G/W", {title: "green or white mana", class:"GW"  }],
        ["R/G", {title: "red or green mana", class:"RG"    }],
        ["R/W", {title: "red or white mana", class:"RW"    }],
        ["U/B", {title: "blue or black mana", class:"UB"   }],
        ["U/R", {title: "blue or red mana", class:"UR"     }],
        ["W/B", {title: "white or black mana", class:"WB"  }],
        ["W/U", {title: "white or blue mana", class:"WU"   }],
        // Phyrexian Mana
        ["B/P", {title: "one black mana or two life", class:"BP"}],         
        ["G/P", {title: "one green mana or two life", class:"GP"}],                
        ["R/P", {title: "one red mana or two life", class:"RP"}],                  
        ["W/P", {title: "one white mana or two life", class:"WP"}],                
        ["U/P", {title: "one blue mana or two life", class:"WU"}],                 
        ["R/G/P", {title: "one red mana or one green or two life", class:"RGP"}], 
        ["G/U/P", {title: "one green mana or one blue or two life", class:"GUP"}],
        // Base Mana
        ["W", {title: "one white mana", class:"W"}],
        ["G", {title: "one green mana", class:"G"}],
        ["R", {title: "one red mana", class:"R"}],
        ["U", {title: "one blue mana", class:"U"}],
        ["B", {title: "one black mana", class:"B" }],
        //Colorless
        ["1", {title: "one generic mana", class:"1"}],
        ["2", {title: "two generic mana", class:"2"}],
        ["3", {title: "three generic mana", class:"3"}],
        ["4", {title: "four generic mana", class:"5"}],
        ["5", {title: "five generic mana", class:"5"}],
        ["6", {title: "six generic mana", class:"6"}],
        ["7", {title: "seven generic mana", class:"7"}],
        ["8", {title: "eight generic mana", class:"7"}],
        ["9", {title: "nine generic mana", class:"9"}],
        ["10", {title: "ten generic mana", class:"10"}],
        ["11", {title: "eleven generic mana", class:"11"}],
        ["12", {title: "twelve generic mana", class:"12"}],
        ["13", {title: "thirteen generic mana", class:"13"}],
        ["14", {title: "fourteen generic mana", class:"14"}],
        ["15", {title: "fifteen generic mana", class:"15", }],
        ["100", {title: "one hundred generic mana", class:"100", style : {height: 15, width : 28}}],
        ["1000000", {title: "one million generic mana", class:"1000000", style : {height: 15, width : 76}}],
    ]
);



export function parseManaCost(manaCost: string): ManaSymbol[] {
    // Regular expression to match patterns within curly braces
    const regex = /{([^}]+)}/g;
    let match;
    const components: ManaSymbol[] = [];

    // Iterate over all matches in the manaCost string
    while ((match = regex.exec(manaCost)) !== null) {
        const symbol = manaValuesMap.get(match[1]);
        if (symbol) {
            components.push(symbol);
        } else {
            console.error(`Mana symbol not found for key: ${match[1]}`);
        }
    }

    return components;
}
