import { Navigate, RouteObject, createBrowserRouter } from "react-router-dom";
import App from "../layout/App";
import ActivityForm from "../../features/activities/form/ActivityForm";
import ActivityDetails from "../../features/activities/details/ActivityDetails";
import TestErrors from "../../features/Views/errors/ErrorsView";
import NotFound from "../../features/Views/errors/NotFound";
import ServerError from "../../features/Views/errors/ServerError";
import ProfilePage from "../../features/profiles/ProfilePage";
import RequireAuth from "./RequireAuth";
import DeckArchetypeView from "../../features/Views/DeckArchetypeView/DeckArchetypeView";
import DeckCreateUrlValidator from "../../features/Views/CreateAndEditDeck/CreateAndEditDeckView";
import DecksListByTypeView from "../../features/Views/DeckList/DecksListByTypeView";
import DeckArchetypeCreateUrlValidator from "../../features/Views/CreateAndEditArchetype/CreateDeckArchetypeView";
import CreateContentCreator from "../../features/Views/CreateAndEditContentCreator/CreateAndEditContentCreatorView";
import Timeline from "../../features/home/TimelineView";
import ContentCreatorsListView from "../../features/Views/ContentCreatorsList/ContentCreatorsListView";
import ContentCreatorView from "../../features/Views/ContentCreator/ContentCreatorView";
import LoginView from "../../features/Views/Login/LoginView";
import DeckChildViewUrlValidator from "../../features/Views/DeckChild/DeckChildView";
import Maintenance from "../../features/Views/Maintenance/Maintenance";

export const routes: RouteObject[] = [
    {
        path: '/',
        element: <App/>,
        children: [
            { path: '', element: <Timeline /> },
            { path: 'deckArchetypeView/:format/:type/:deckArchetypeId/:deckChildId?', element: <DeckArchetypeView /> },
            { path: 'deck/:deckChildId?', element: <DeckChildViewUrlValidator /> },
            { path: 'activities/:id', element: <ActivityDetails /> },
            { path: 'decklist/', element: <DecksListByTypeView /> },
            { path: 'login/', element: <LoginView /> },
            { path: 'createActivity', element: <ActivityForm key='create'/> },
            { path: 'manage/:id', element: <ActivityForm key='manage'/> },
            { path: 'profiles/:username', element: <ProfilePage/> },
            { path: 'contentCreators', element: <ContentCreatorsListView/> },
            { path: 'contentCreator/:contentCreatorId', element: <ContentCreatorView /> },
            { path: 'errors', element: <TestErrors/> },
            { 
                element: <RequireAuth />, 
                children: [
                    { path: 'createAndEditDeck/:deckChildId?', element: <DeckCreateUrlValidator /> },
                    { path: 'createAndEditContentCreator/:contentCreatorId?', element: <CreateContentCreator /> },
                    { path: 'createAndEditArchetype/:deckArchetypeId?', element: <DeckArchetypeCreateUrlValidator /> },
                    { path: 'Maintenance', element: <Maintenance /> },
                ]
            },
            { path: 'not-found', element: <NotFound/> },
            { path: 'server-error', element: <ServerError/> },
            { path: '*', element: <Navigate replace to='/not-found'/> }
        ]
    }
];

export const router = createBrowserRouter(routes)