import { makeAutoObservable, reaction} from "mobx"
import agent from "../api/agent"
import { Pagination, PagingParams } from "../models/pagination"
import { News } from "../models/news"
import { format } from "date-fns"

export default class NewsStore {
    newsRegistry = new Map<string, News>()
    loading = false
    pagination: Pagination | null = null;
    pagingParams = new PagingParams();
    predicate = new Map().set('all', true);

    constructor() {
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.pagingParams = new PagingParams();
                this.newsRegistry.clear()
                this.loadNews();
            }
        )
    }

    initialLoad = async () => {
        this.pagingParams = new PagingParams();
        this.newsRegistry.clear()
        this.loadNews();
    }

    loadNews = async () => {
        this.setLoading(true);

        try {
            const result = await agent.News.list(this.axiosParams)
            const newsList = result.data.map(news => News.createFromAnyObject(news))
            newsList.forEach(news => {
                this.setNews(news)
            })

            this.setPagination(result.pagination);

        } catch (error) {
 
        } finally {
            this.setLoading(false)
        }
    }

    setPagination = (pagination: Pagination) => {
        this.pagination = pagination;
    }

    setPagingParams = (pagingParams: PagingParams) => {
        this.pagingParams = pagingParams;
    }

    setPredicate = (predicate: string, value: string | Date) => {
        const resetPredicate = () => {
            this.predicate.forEach((value, key) => {
                if (key !== 'startDate') this.predicate.delete(key);
            })
        }
        switch (predicate) {
            case 'all':
                resetPredicate();
                this.predicate.set('all', true);
                break;
            case 'isGoing':
                resetPredicate();
                this.predicate.set('isGoing', true);
                break;
            case 'isHost':
                resetPredicate();
                this.predicate.set('isHost', true);
                break;
            case 'startDate':
                this.predicate.delete('startDate');
                this.predicate.set('startDate', value);
                break;
        }
    }

    private setNews = (news: News) => {
        var newsId = news.deckChildId + ""
        if(news.content != null){
            newsId += news.content.id
        }
        this.newsRegistry.set(newsId, news)
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('pageNumber', this.pagingParams.pageNumber.toString());
        params.append('pageSize', this.pagingParams.pageSize.toString())
        this.predicate.forEach((value, key) => {
            if (key === 'startDate') {
                params.append(key, (value as Date).toISOString())
            } else {
                params.append(key, value);
            }
        })
        return params;
    }

    get NewsByDate() {
        return Array.from(this.newsRegistry.values()).sort((a, b) => b.updatedAt!.getTime() - a.updatedAt!.getTime() )
    }

    get groupedNews() : {[key : string] : News[]} {
        const NewsByDate = this.NewsByDate;
        const newLocal = NewsByDate.reduce((newsList, news) => {
            var date = format(news.updatedAt!, 'dd MMM yyyy');
            newsList[date] = newsList[date] ? [...newsList[date], news] : [news];
            return newsList;
        }, {} as { [key: string]: News[]; });
        return newLocal
    }

    setLoading = (state: boolean) => {
        this.loading = state
    }
} 