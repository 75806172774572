import React, { useState } from 'react';
import { Button, Grid } from 'semantic-ui-react';
import ViewByDeckList from './ViewByDeckList/ViewByDeckListComponent';
import ViewByArchetypeList from './ViewByArchetypeList/ViewByArchetypeListComponent';
import CacheService from '../../../CacheService';

const DecksListByTypeView = () => {
  const [viewMode, setViewMode] = useState('decks');
  const [formatId, setFormat] = useState(3);
  const [bestOfOne, setBestOfOne] = useState(false);

  return (
    <Grid centered>
      <Grid.Row>
        <Grid.Column textAlign="center">
          <Button.Group>
            {Object.entries(CacheService.getInstance().formats).map(([key, name], index) => (
              <React.Fragment key={key}>
                {index > 0 && <Button.Or />}
                <Button
                  positive={formatId === parseInt(key)}
                  onClick={() => setFormat(parseInt(key))}
                >
                  {name}
                </Button>
              </React.Fragment>
            ))}
          </Button.Group>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column textAlign="center">
          <Button.Group>
              <React.Fragment>
                
                <Button
                  positive={!bestOfOne}
                  onClick={()=> setBestOfOne(false)}
                >
                  Best Of Three                 
                </Button> <Button.Or />
              </React.Fragment>
              <React.Fragment>
                <Button
                  positive={bestOfOne}
                  onClick={()=> setBestOfOne(true)}
                >
                  Best Of One                 
                </Button>
              </React.Fragment>
          </Button.Group>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column textAlign="center">
          <Button.Group>
            <Button 
              positive={viewMode === 'decks'} 
              onClick={() => setViewMode('decks')}
            >
              By Decks
            </Button>
            <Button.Or />
            <Button 
              positive={viewMode === 'archetypes'} 
              onClick={() => setViewMode('archetypes')}
            >
              By Archetypes
            </Button>
          </Button.Group>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          {viewMode === 'decks' ? (
            <ViewByDeckList formatId={formatId} bestOfOne={bestOfOne} />
          ) : (
            <ViewByArchetypeList formatId={formatId} bestOfOne={bestOfOne}/>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default DecksListByTypeView