import PatchContentCreatorRequestDto from "../api/dto/ContentCreator/patchContentCreatorRequestDto";
import PostContentCreatorRequestDto from "../api/dto/ContentCreator/postContentCreatorRequestDto";
import DeckChild from "./deckChild";

export default interface ContentCreator {
    id: number
    name: string
    contentCreatorSocialMedias : ContentCreatorSocialMedia[]
    contents : Content[]
}

export default class ContentCreator {
    
    constructor(
        public id: number,
        public name: string,
        public contentCreatorSocialMedias : ContentCreatorSocialMedia[],
        public contents : Content[]
    ) {
        
    }

    static createEmptyObject(): ContentCreator { return new ContentCreator(0, '', [], []); }   

    convertToPostContentCreatorRequestDto() : PostContentCreatorRequestDto {
        return {
            name: this.name
        }
    }

    convertToPatchContentCreatorRequestDto() : PatchContentCreatorRequestDto {
        return {
            id : this.id,
            name: this.name,
            contentCreatorSocialMedias: this.contentCreatorSocialMedias.map( contentCreatorSocialMedia => ({...contentCreatorSocialMedia})),
            contents: this.contents.filter(content => content.url).map(content => ({...content, id : content.id <= 0 ? 0 : content.id}))
        }
    }

    static createFromObject(obj: Partial<ContentCreator>): ContentCreator {
        return new ContentCreator(
            obj.id ?? 0,
            obj.name ?? '',
            obj.contentCreatorSocialMedias ?? [],
            obj.contents ?? []
        );
    }

    static createFromAnyObject(input: any): ContentCreator {
        return new ContentCreator(
            input.id ?? 0,
            input.name ?? '',
            Array.isArray(input.contentCreatorSocialMedias) ? input.contentCreatorSocialMedias.map((socialMedia: any) => new ContentCreatorSocialMedia(
                socialMedia.id ?? 0,
                socialMedia.platformId ?? 0,
                socialMedia.username ?? ''
            )) : [],
            Array.isArray(input.contents) ? input.contents.map((content: any) => Content.createFromAnyObject(content)) : []
        );
    }
}

export interface Content {
    id : number
    platformId: number
    url: string
    publishDate : Date
    contentTypeIds : number[]
}

export class Content {
    static lastTempId = 0
    id : number
    platformId: number
    url: string
    publishDate : Date
    contentTypeIds : number[]
    deckChild : DeckChild
    title : string

    constructor(id: number, platformId: number, url: string, publishDate : Date, contentTypeIds : number[], childDeck : any, title : string) {
        this.id = (id === 0 ? Content.generateTempId() : id)
        this.platformId = platformId
        this.url = url
        this.publishDate = publishDate
        this.contentTypeIds = contentTypeIds
        this.deckChild = DeckChild.createFromAnyObject(childDeck)
        this.title = title
    }

    static createFromObject(data: { id?: number, platformId: number, url: string, publishDate : Date, contentTypeIds : number[], deckChild : Object, title : string }): Content {
        const id = data.id === 0 || data.id === undefined ? this.generateTempId() : data.id;
        return new Content(id, data.platformId, data.url, data.publishDate, data.contentTypeIds, data.deckChild, data.title);
    }

    static createFromAnyObject(input: any): Content {
        return new Content(
            input.id ?? 0,
            input.platformId ?? 0,
            input.url ?? '',
            input.publishDate ?? new Date(),
            input.contentTypeIds ?? [],
            input.deckChild ?? {},
            input.title ?? ""
        );
    }

    static createEmpty() : Content{
        return new Content(0, 0, "", new Date(), [], {}, "")
    }

    static generateTempId() {
        Content.lastTempId -= 1
        return Content.lastTempId
    }
}

interface ContentCreatorSocialMedia {
    id: number
    platformId: number
    username: string
}

class ContentCreatorSocialMedia {
    id: number
    platformId: number
    username: string

    constructor(id: number, platformId: number, username: string) {
        this.id = id;
        this.platformId = platformId;
        this.username = username;
    }

    static createFromAnyObject(object : any) {
        return new ContentCreatorSocialMedia(
            object.id ?? 0,
            object.platformId ?? 0,
            object.username ?? ""
        ) 
    }
}