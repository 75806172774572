import './ToggleSwitch.css';

const ToggleSwitch = ({ onToggle, isToggled }: { onToggle: (toggle: boolean) => void, isToggled: boolean }) => {

  const handleClick = () => {
      onToggle(!isToggled);
  };

  return (
    <div className="toggle-container">
      <div className="toggle-switch-container">
        <label className="toggle-switch">
          <input type="checkbox" checked={isToggled} onChange={handleClick} />
          <span className="switch-slider"></span>
        </label>
        <span className="switch-legend">Show Differences</span>
      </div>
      {isToggled && (
        <div className="color-labels-container">
          <span className="color-label" style={{backgroundColor: 'red'}}>Removed</span>
          <span className="color-label" style={{backgroundColor: 'green'}}>Added</span>
          <span className="color-label" style={{backgroundColor: 'orange'}}>Changed</span>
        </div>
      )}
    </div>
  )
};

export default ToggleSwitch;
