import { useEffect } from 'react';
import PropTypes from 'prop-types';

interface Props {
  tweetId: string;
}
const TwitterEmbedComponent = ({ tweetId } : Props) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://platform.twitter.com/widgets.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [tweetId]);

  return (
    <blockquote className="twitter-tweet">
      <a href={`https://twitter.com/user/status/${tweetId}`}>View Tweet</a>
    </blockquote>
  );
};

TwitterEmbedComponent.propTypes = {
  tweetId: PropTypes.string.isRequired,
};

export default TwitterEmbedComponent;
