import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

interface IContentListWithoutDeckChild {
    [key: number]: any;
  }

export default class ContentStore {
    contentListWithoutDeckChild:IContentListWithoutDeckChild = {};
    updating = false
    loading = false

    constructor() {
        makeAutoObservable(this);
    }

    requestContentWithoutDeckChildList = async (platformId : number = -1, includeDeckChildId : number = -1) => {
        this.setLoading(true);
        try {
            const foundContents = await agent.Content.getWithoutDeckChildListItems(platformId, includeDeckChildId);
            runInAction(() => {
                Object.keys(this.contentListWithoutDeckChild).forEach(key => {
                    delete this.contentListWithoutDeckChild[Number(key)];
                });
                
                foundContents.forEach(contentData => {
                    this.contentListWithoutDeckChild[contentData.id] = contentData
                });
            });
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            this.setLoading(false);
        }
    }

    setLoading(state: boolean) {
        this.loading = state
    }

}
