import { makeAutoObservable, runInAction } from "mobx"
import agent from "../api/agent"
import Card from "../models/card";

export default class CardStore {

    autocompleteOptions : Card[] = [];
    lastestCardFound : Card = Card.createEmptyCard()
    loadingAutocomplete = false;

    constructor() {
        makeAutoObservable(this)
    }


    fetchCardAutocomplete = async (token: string) => {
        this.setLoadingAutocomplete(true)
        try {
          const response = await agent.Cards.getCardAutocompleteByToken(token);
          const cards = response.map(card => Card.createFromAnyObject(card))
          runInAction(()=>{
            this.autocompleteOptions = cards
          })
        } catch (error) {
          console.error('Error fetching data:', error);
        }
        this.setLoadingAutocomplete(false)
      }

    getCardById = async (nameId : string) => {
        try{
            var foundCard = Card.createFromObject(await agent.Cards.getCardByNameId(nameId));

            runInAction(()=>{
                this.lastestCardFound = foundCard
            })
        }catch(error){
            throw error
        }
    }

    setLoadingAutocomplete = (state: boolean)=>{
        this.loadingAutocomplete = state
    }
} 