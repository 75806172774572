import React, { useState, useEffect } from 'react'
import { Button, Card, Grid, Icon, Label, Loader } from 'semantic-ui-react'
import { Link, useNavigate } from "react-router-dom"
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../../app/stores/store'
import CacheService from '../../../../CacheService'
import RenderManaCost from '../../../../app/common/RenderManaCost'
import { format } from 'date-fns'
import { getColorFromTagName } from '../../../../app/models/tag'
import DeckListFilterBox from './DeckListFilterBox'

const ViewByDeckListComponent = ({ formatId, bestOfOne }: { formatId: number, bestOfOne : boolean }) => {

  const { deckChildStore: deckStore, userStore } = useStore()
  const [searchTerm, setSearchTerm] = useState<{ term: string, tags: Set<number>, colors: Set<string> }>({ term: "", tags: new Set<number>(), colors: new Set<string>() })
  const navigate = useNavigate()

  useEffect(() => {
    const fetchDeckChilds = async () => {
      await deckStore.loadDeckChildList(formatId, bestOfOne)
    };
    fetchDeckChilds()
  }, [deckStore.loadDeckChildList, deckStore, formatId, bestOfOne])

  const filteredDeckListItems = deckStore.deckListItems.filter(item => {
    const matchesSearchTerm = item.name && item.name.toLowerCase().includes(searchTerm.term.toLowerCase())
    const matchesTags = searchTerm.tags.size === 0 || item.tagIds?.some(tagId => searchTerm.tags.has(tagId))
    const matchesColors = searchTerm.colors.size === 0 || Array.from(searchTerm.colors).every(color =>
      CacheService.getInstance().colorCombinations[item.colorCombinationId].colors.find(manaSymbol => manaSymbol.class === color)
    );
    return matchesSearchTerm && matchesTags && matchesColors
  });

  const handleEditClick = (event: React.MouseEvent<HTMLButtonElement>, itemId: number) => {
    event.preventDefault()
    event.stopPropagation()
    navigate(`/createAndEditDeck/${itemId}`, )
  };

  if (deckStore.loading) {
    return <Loader active inline="centered" />
  }

  return (
    <>
      <Grid centered style={{ marginTop: '20px'}}>
        <DeckListFilterBox searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      </Grid>
      <Grid centered stackable>
        <Grid.Row>
          {filteredDeckListItems.map((deckChild, index) => (
            <Grid.Column key={index} computer={4} tablet={5} mobile={8} style={{ marginBottom: '20px' }}>
              <Link to={`/deckArchetypeView/${CacheService.getInstance().formats[formatId]}/${bestOfOne ? "BO1" : "BO3"}/${deckChild.deckArchetypeId}/${deckChild.id}`} style={{ textDecoration: 'none' }}>
                <Card centered style={{ height: '270px', display: 'flex', flexDirection: 'column' }}>
                  <Card.Content style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <Card.Header style={{height: "55px"}}>
                      {deckChild.name ?? 'EMPTY'}
                      {userStore.isLoggedIn ?
                        <Button icon size='tiny' onClick={(e) => handleEditClick(e, deckChild.id)} style={{ marginLeft: '10px' }}>
                          <Icon name='edit' />
                        </Button>
                        : null
                      }
                    </Card.Header>
                    <Card.Meta>Format: {CacheService.getInstance().formats[deckChild.formatId] ?? 'EMPTY'}</Card.Meta>
                    <Card.Description>
                      Archetype: {deckChild.deckArchetypeName ?? 'None'}
                    </Card.Description>
                    <Card.Description>
                      <RenderManaCost colors={deckChild.colorCombinationId} />
                    </Card.Description>
                    <Card.Description>
                      Update: {CacheService.getInstance().deckLatestStates[deckChild.deckLatestState]} on {format(new Date(deckChild.updatedDate), 'dd MMM')}
                    </Card.Description>
                    <div style={{ marginTop: 'auto', paddingTop: '10px' }}>
                      {deckChild.tagIds && deckChild.tagIds.map(tagId => (
                        <Label key={tagId} size='tiny' color={getColorFromTagName(CacheService.getInstance().tags[tagId]!)}>{CacheService.getInstance().tags[tagId]}</Label>
                      ))}
                    </div>
                  </Card.Content>
                </Card>
              </Link>
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </>
  );
}

export default observer(ViewByDeckListComponent)
