import { Grid } from "semantic-ui-react";
import ProfileContent from "./ProfileContent";
import ProfileHeader from "./ProfileHeader";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { useEffect } from "react";
import LoadingComponents from "../../app/layout/LoadingComponent";

export default observer(function ProfilePage() {
    const {username} = useParams<{username: string}>()
    const {profileStore} = useStore()
    const {loadingProfile, loadProfile, profile, setActiveTab} = profileStore

    useEffect(()=> {
        if(username)
        loadProfile(username)
        return () => {
            setActiveTab(0)
        }

    }, [loadProfile, username, setActiveTab])

    if(loadingProfile) return <LoadingComponents content="Loading profile..." /> 
    
    return (
        <Grid>
            <Grid.Column width={16}>
                {profile && 
                <>
                    <ProfileHeader profile={profile}/>
                    <ProfileContent profile={profile}/>
                </>}
            </Grid.Column>
        </Grid>
    ) 
})