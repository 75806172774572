import { Button, Segment } from "semantic-ui-react"
import agent from "../../../app/api/agent"
import { useEffect } from "react"

const DeckChildCardsEditFieldComponent = () => {


    useEffect(()=>{

    }, [])
    return <>
        <Segment>
            <Button onClick={agent.Maintenance.updateImages}> Update Cards Images </Button>
        </Segment>
        <Segment>
            <Button onClick={agent.Maintenance.updateCardDatabase}> Update Deck Database </Button>
        </Segment>
    </>
}

export default DeckChildCardsEditFieldComponent