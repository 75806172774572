import { useState, useEffect } from 'react';
import { Container, Loader, Segment } from 'semantic-ui-react';
import { useStore } from '../../app/stores/store';
import { observer } from 'mobx-react-lite';
import NewsList from './NewsList';
import InfiniteScroll from 'react-infinite-scroller';
import { PagingParams } from '../../app/models/pagination';

const Timeline = observer(() => {

    const { newsStore } = useStore();
    const [loadingNext, setLoadingNext] = useState(false);
    const { pagination, setPagingParams, loadNews, initialLoad } = newsStore
    
    function handleGetNext() {
        setLoadingNext(true);
        setPagingParams(new PagingParams(pagination!.currentPage + 1));
        loadNews().then(() => setLoadingNext(false));
    }

    useEffect(() => {
        initialLoad();
    }, [loadNews, initialLoad]);

    return (
        <Segment>
            <Container>
                <InfiniteScroll 
                    pageStart={0}
                    loadMore={handleGetNext}
                    hasMore={!loadingNext && !!pagination && pagination.currentPage < pagination.totalPages}
                    initialLoad={false}
                >
                    <NewsList/>
                </InfiniteScroll>
            </Container>
            <Container>
                <Loader active={loadingNext} />
            </Container>
        </Segment>
    )
})

export default Timeline;
