import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { ChatComment } from "../models/comment";
import { makeAutoObservable, runInAction } from "mobx";
import { store } from "./store";

export default  class CommentStore {
    comments: ChatComment[] = []
    hubConnection: HubConnection | null = null

    constructor(){
        makeAutoObservable(this)
    }

    createHubConnection = (ActivityId: string) => {
        if(store.activityStore.selectedActivity){
            this.hubConnection = new HubConnectionBuilder()
                .withUrl(process.env.REACT_APP_CHAT_URL + '?activityId=' + ActivityId, {
                    accessTokenFactory: () => store.userStore.user?.token!
                })
                .withAutomaticReconnect()
                .configureLogging(LogLevel.Information)
                .build();

                this.hubConnection.start().catch(error => console.log('Error stablishing the connection'))

                this.hubConnection.on('LoadComments', (comments: ChatComment[]) => {

                    runInAction(()=> {
                        comments.forEach(comment => {
                            comment.createdDate = new Date(comment.createdDate + 'Z')
                        })
                        this.comments = comments
                    })
                })

                this.hubConnection.on('ReceiveComment', (comment: ChatComment) =>{
                    
                    runInAction(()=> 
                    {
                        comment.createdDate = new Date(comment.createdDate)
                        this.comments.unshift(comment);
                    })}   
                )


        }
    }

    stopHubConnection = () => {
        this.hubConnection?.stop().catch(error => console.log("Error stopping connection: ", error))
    }

    clearComments = () => {
        this.comments = []
        this.stopHubConnection()
    }

    addComment = async (values: any) => {
        values.activityId = store.activityStore.selectedActivity?.id
        try {
            await this.hubConnection?.invoke('SendComment', values)
        } catch (error) {
            console.log(error)
            
        }
    }

}