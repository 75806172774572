import { useState } from "react";
import { Button, Checkbox, Grid, Input } from "semantic-ui-react";
import CacheService from "../../../../CacheService";
import RenderManaCost from "../../../../app/common/RenderManaCost";
import { manaValuesMap } from '../../../../app/models/manaSymbol'

interface Props {
    searchTerm: { term: string, tags: Set<number>, colors: Set<string> },
    setSearchTerm : React.Dispatch<React.SetStateAction<{
        term: string;
        tags: Set<number>;
        colors: Set<string>;
    }>>
}

const DeckListFilterBox = ({searchTerm, setSearchTerm} : Props) =>
{
    const [isExpanded, setIsExpanded] = useState(false)

    return <Grid.Column style={{ maxWidth: 800 }}>
    <div style={{ border: '2px solid #ddd', padding: '4px', borderRadius: '2px' }}>
      <Input
        icon='search'
        placeholder='Search decks...'
        value={searchTerm.term}
        onChange={e => setSearchTerm({ ...searchTerm, term: e.target.value })}
        style={{ width: '100%', boxSizing: 'border-box' }}
      />
      {isExpanded && (
        <>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <h2>Tags</h2>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              {Object.entries(CacheService.getInstance().tags).map(([tagId, tag]) => (
                <Grid.Column key={tagId} width={4}>
                  <Checkbox
                    label={tag}
                    name={tagId}
                    checked={searchTerm.tags.has(parseInt(tagId))}
                    onChange={() => {
                      setSearchTerm(prevState => {
                        const newTags = new Set(prevState.tags)
                        newTags.has(Number(tagId)) ? newTags.delete(Number(tagId)) : newTags.add(Number(tagId))
                        return { ...prevState, tags: newTags }
                      });
                    }}
                  />
                </Grid.Column>
              ))}
            </Grid.Row>
          </Grid>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <h2>Colors</h2>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              {["W", "U", "B", "R", "G"].map(color => (
                <Grid.Column key={color} width={3}>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={1}>
                        <Checkbox
                          name={color}
                          checked={searchTerm.colors.has(color)}
                          onChange={() => {
                            setSearchTerm(prevState => {
                              const newColors = new Set(prevState.colors)
                              newColors.has(color) ? newColors.delete(color) : newColors.add(color)
                              return { ...prevState, colors: newColors }
                            });
                          }}
                        />
                      </Grid.Column>
                      <Grid.Column width={1}>
                        <RenderManaCost colors={[manaValuesMap.get(color)!]} />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              ))}
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Button type='button' size='tiny' onClick={() => setSearchTerm({ term: "", tags: new Set<number>(), colors: new Set<string>() })}>
                  clear Filters
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </>
      )}
      <Button type='button' size='tiny' onClick={() => setIsExpanded(!isExpanded)}>
        {isExpanded ? 'Hide' : 'More Filter'}
      </Button>
    </div>
  </Grid.Column>
}

export default DeckListFilterBox