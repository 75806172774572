export default interface Card {
    name: string
    manaCost: string
    types: string
}

export default class Card {
    constructor(
        public name: string, 
        public manaCost: string,
        public types : string 
    ) {

    }

    static createFromObject(obj: Partial<Card>): Card {
        return new Card(
            obj.name ?? '',
            obj.manaCost ?? '',
            obj.types ?? ''
        );
    }

    static createFromAnyObject(input: any): Card {
        return new Card(
            ( input.nameId || input.name ) ?? '',
            input.manaCost ?? '',
            input.types ?? ''
        )
    }

    static createEmptyCard() : Card{
        return new Card("", "", "")
    }
}
