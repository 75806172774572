import { Item, ItemContent, ItemHeader, ItemMeta, Label, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { getColorFromTagName } from "../../app/models/tag";
import CacheService from "../../CacheService";
import { News } from "../../app/models/news";

function getBorderColorFromState(deckLatestState : number) {
    switch (deckLatestState) {
        case 0:
            return 'red';
        case 1:
            return 'green';
        case 2:
            return 'blue';
        default:
            return 'gray';
    }
}

export default function NewsItem({ news }: { news: News }) {
    return (
        <Segment>
            <Item key={news.deckChildId} style={{ borderLeft: `5px solid ${getBorderColorFromState(news.deckLatestState)}`, paddingLeft: '10px' }}>
                <ItemContent>
                    <ItemHeader style={{ fontSize: '1.5em' }} as={Link} to={news.deckArchetypeId ? `/deckArchetypeView/${CacheService.getInstance().formats[news.formatId]}/${news.bestOfOne ? "BO1" : "BO3"}/${news.deckArchetypeId}/${news.deckChildId}` : `/deck/${news.deckChildId}`}>{news.name}</ItemHeader>
                    <ItemMeta>
                        {CacheService.getInstance().deckLatestStates[news.deckLatestState]}
                    </ItemMeta>
                    <ItemMeta>
                        <p>{news.archetypeName || "No additional information available."}</p>
                    </ItemMeta>
                    <ItemMeta>
                        {news.tagIds && news.tagIds.map(tagId => (
                            <Label
                                key={tagId}
                                size='tiny'
                                color={getColorFromTagName(CacheService.getInstance().tags[tagId])}
                                style={{ marginBottom: '5px' }}
                            >
                                {CacheService.getInstance().tags[tagId]}
                            </Label>
                        ))}
                    </ItemMeta>
                </ItemContent>
            </Item>
        </Segment>
    );
}
