import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import { useEffect } from "react";
import { Button, Card, Container, Grid, Icon, Label, Loader, Segment } from "semantic-ui-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import CacheService from "../../../CacheService";
import ContentCreator from "../../../app/models/contentCreator";
import YouTubeVideoComponent from "../DeckArchetypeView/YoutubeVideoComponent";
import { getColorFromTagName } from "../../../app/models/tag";

const ContentCreatorView = () => {
    const navigate = useNavigate();
    const { contentCreatorId: contentCreatorIdStr } = useParams();
    const { contentCreatorStore } = useStore();
    const contentCreatorId = Number(contentCreatorIdStr);
    const isContentCreatorIdNumber = !isNaN(contentCreatorId);

    useEffect(() => {
        const validateContentCreatorId = async () => {
            if (isContentCreatorIdNumber) {
                try {
                    await contentCreatorStore.loadContentCreatorById(contentCreatorId, true);
                } catch (error) {
                    console.error(error);
                    navigate('/', { replace: true });
                }
            } else {
                navigate('/', { replace: true });
            }
        };

        validateContentCreatorId();
    }, [isContentCreatorIdNumber, contentCreatorId, navigate, contentCreatorStore]);

    if (contentCreatorStore.loading) {
        return <Loader active inline="centered" />;
    }

    return <ContentCreatorDetails contentCreator={contentCreatorStore.contentCreator} />;
};

const ContentCreatorDetails = ({ contentCreator }: { contentCreator: ContentCreator }) => {
    const navigate = useNavigate();
    const { modalStore, userStore } = useStore();
    const getPlatformUrl = (platformId: number, username: string) => {
        const platform = CacheService.getInstance().platforms[platformId].toLowerCase();
        return `https://${platform}.com/${platform === "youtube" ? "@" : ""}${username}`;
    }

    return (
        <>
            <Segment raised>
                <Grid textAlign="center">
                    <Grid.Row style={{ paddingBottom: '0.5rem' }}>
                        <Grid.Column>
                            <h2>
                                {contentCreator.name}
                                {userStore.isLoggedIn ?
                                <span style={{ marginLeft: '10px' }}>
                                    <Button icon size='tiny' onClick={(e) => navigate(`/createAndEditContentCreator/${contentCreator.id}`, { replace: true })}>
                                        <Icon name='edit' />
                                    </Button>
                                </span>
                                : null}    
                            </h2> 
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{ paddingTop: '0.5rem' }}>
                        <Grid.Column>
                            {contentCreator.contentCreatorSocialMedias.map((socialMedia, index) => (
                                <Label key={index} image style={{ marginLeft: '5px', minWidth: '100px' }}>
                                    <i className={`${CacheService.getInstance().platforms[socialMedia.platformId].toLowerCase()} icon` } />
                                    <a href={getPlatformUrl(socialMedia.platformId, socialMedia.username)} target="_blank" rel="noopener noreferrer">
                                        {socialMedia.username}
                                    </a>
                                </Label>
                            ))}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
            <Segment raised>
            <Container>
                <Card.Group itemsPerRow={1}>
                    {contentCreator.contents.map(content => (
                        <Card key={content.id}>
                            <Card.Content>
                                <Card.Header>
                                    {content.title}
                                </Card.Header>
                                <Card.Content>
                                    {content.deckChild ? (content.deckChild.name ? <Link style={{fontSize : 20}} to={`/deckArchetypeView/${content.deckChild.deckArchetypeId}/${content.deckChild.id}`}>{content.deckChild.name}</Link> : <span style={{color : "red"}}>No Linked Deck</span>) : <span style={{color : "red"}}>No Linked Deck</span>} {content.deckChild.tagIds.map(tagId => <Label circular key={tagId} size='tiny' color={getColorFromTagName(CacheService.getInstance().tags[tagId]!)}>{CacheService.getInstance().tags[tagId]}</Label>)}
                                </Card.Content>
                                <Card.Description>
                                    <Label horizontal image style={{ minWidth: '75px' }} onClick={()=>{modalStore.openModal(<YouTubeVideoComponent videoId={content.url}/>)}}>
                                        <i className={`youtube icon`} />
                                        Youtube
                                    </Label>
                                </Card.Description>
                            </Card.Content>
                            <Card.Content extra>
                                {content.contentTypeIds.map(contentTypeId => <Label tag key={contentTypeId} size='tiny' color={getColorFromTagName(CacheService.getInstance().contentTypes[contentTypeId]!)}>{CacheService.getInstance().contentTypes[contentTypeId]}</Label>)}
                            </Card.Content>
                        </Card>
                    ))}
                </Card.Group>
            </Container>
            </Segment>

        </>
    );
};

export default observer(ContentCreatorView);
