import { useState } from "react";
import { Grid, GridRow, GridColumn, Dropdown, TextArea, Button, Message } from "semantic-ui-react";
import DeckChild from "../../../app/models/deckChild";
import CacheService from "../../../CacheService";
import DeckCardInfo from "../../../app/models/deckCard";

const DeckChildExportView = ({ deckChild }: { deckChild: DeckChild }) => {

    const [selectedType, setSelectedType] = useState('magicArena')

    const dropdownOptions = [
        { key: 1, text: 'Magic Arena', value: 'magicArena' },
    ]

    const [copied, setCopied] = useState(false);

    const makeTextAreaValue = () => {

        const fields: { [key: number]: DeckCardInfo[] } = {};

        for (const key in CacheService.getInstance().defaultLocations) {
            fields[Number(key)] = [];
        }

        deckChild.deckCardInfos.forEach( deckCardInfo => {
            fields[deckCardInfo.defaultLocationId].push(deckCardInfo)
        })

        var exportText = ""

        //Companion
        const companionCards = fields[CacheService.getInstance().defaultLocationsByName["Companion"]]
        if(companionCards.length){
            exportText += "Companion\n"
            companionCards.forEach( deckCardInfo => { exportText += deckCardInfo.quantity + " " + deckCardInfo.getSplitNames()[0] + "\n" })
            exportText += "\n"
        }

        const mainCards = fields[CacheService.getInstance().defaultLocationsByName["Main"]]
        if(mainCards.length){
            exportText += "Deck\n"
            mainCards.forEach( deckCardInfo => { exportText += deckCardInfo.quantity + " " + deckCardInfo.getSplitNames()[0] + "\n" })
            exportText += "\n"
        }

        const sideboardCards = fields[CacheService.getInstance().defaultLocationsByName["Sideboard"]]
        if(sideboardCards.length){
            exportText += "Sideboard\n"
            sideboardCards.forEach( deckCardInfo => { exportText += deckCardInfo.quantity + " " + deckCardInfo.getSplitNames()[0] + "\n" })
        }

        return exportText
    }

    var exportText = makeTextAreaValue()

    const handleCopyToClipboard = () => {
        // Assuming some text is copied to clipboard here
        navigator.clipboard.writeText(exportText);

        // Show the copied message
        setCopied(true);

        // Hide the copied message after 2 seconds
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    return (
        <Grid>
            <GridRow>
                <GridColumn width={8}>
                    <h1>{deckChild.name}</h1>
                </GridColumn>
                <GridColumn width={8} textAlign="right">
                    <Dropdown
                        selection
                        options={dropdownOptions}
                        defaultValue={selectedType}
                        onChange={(_, {value})=> setSelectedType(value as string)}
                    />
                </GridColumn>
            </GridRow>
            <GridRow>
                <GridColumn width={16} textAlign="right">
                    <Button onClick={handleCopyToClipboard}>Copy to Clipboard</Button>
                    {copied && (
                        <Message
                            positive
                            compact
                            style={{
                                position: 'absolute',
                                top: '-40px', // Adjust based on your design
                                right: '0',
                                zIndex: 10,
                                margin: '0'
                            }}
                        >
                            <Message.Header>Copied!</Message.Header>
                        </Message>
                    )}
                </GridColumn>
            </GridRow>
            <GridRow>
                <GridColumn width={16}>
                    <TextArea
                        value={exportText}
                        rows={countLines(exportText) + 1}
                        placeholder="Enter text here"
                        style={{ width: '100%' }}
                    />
                </GridColumn>
            </GridRow>

        </Grid>
    );
};

function countLines(text: string): number {
    if (text.length === 0) {
        return 0;
    }
    return text.split('\n').length;
}

export default DeckChildExportView;
