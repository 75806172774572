enum CardType {
    Artifact = "artifact",
    Creature = "creature",
    Enchantment = "enchantment",
    Instant = "instant",
    Land = "land",
    Phenomenon = "phenomenon",
    Planeswalker = "planeswalker",
    Scheme = "scheme",
    Sorcery = "sorcery",
    Tribal = "tribal",
    Other = "other"
}

export default CardType