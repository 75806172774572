import axios, { AxiosError, AxiosResponse } from "axios";
import { Activity, ActivityFormValues } from "../models/activity";
import { toast } from "react-toastify";
import { router } from "../router/Routes";
import { store } from "../stores/store";
import { User, UserFormValues } from "../models/user";
import { Photo, Profile, UserActivity } from "../models/profile";
import { PaginatedResult } from "../models/pagination";
import GetCardAutocompleteByTokenDto from "./dto/card/GetCardAutocompleteByTokenDto";
import { NewsFlatListItemDto } from "./dto/news/newsFlatDto";
import GetAllDeckChildListItemsDto from "./dto/deckChild/getAllDeckChildListItemsDto";
import { PatchDeckChildRequestDto } from "./dto/deckChild/patchDeckChildRequestDto";
import { PostDeckChildRequestDto } from "./dto/deckChild/postDeckChildRequestDto";
import GetContentCreatorByIdDto from "./dto/ContentCreator/getContentCreatorByIdDto";
import PostContentCreatorResponseDto from "./dto/ContentCreator/postContentCreatorRequestDto";
import { GetAllContentCreatorsDto } from "./dto/ContentCreator/getAllContentCreatorsDto";
import PatchContentCreatorRequestDto from "./dto/ContentCreator/patchContentCreatorRequestDto";
import PatchContentCreatorResponseDto from "./dto/ContentCreator/patchContentCreatorResponseDto";
import PostContentCreatorRequestDto from "./dto/ContentCreator/postContentCreatorRequestDto";
import { GetCardByIdDto as GetCardByNameIdDto } from "./dto/card/GetCardByIdDto";
import GetDeckArchetypesByFormatIdDto from "./dto/deckArchetype/getDeckArchetypesByFormatIdDto";
import GetDeckArchetypeAllListItemDto from "./dto/deckArchetype/getDeckArchetypeAllListItemDto";
import { GetDeckArchetypeByIdDto } from "./dto/deckArchetype/getDeckArchetypeByIdDto";
import PostDeckArchetypeRequestDto from "./dto/deckArchetype/postDeckArchetypeRequestDto";
import PatchDeckArchetypeRequestDto from "./dto/deckArchetype/patchDeckArchetypeRequestDto";
import PatchDeckArchetypeResponseDto from "./dto/deckArchetype/patchDeckArchetypeResponseDto";
import GetDeckArchetypeWithDeckChildsByIdDto from "./dto/deckArchetype/getDeckArchetypeWithDeckChildsByIdDto";
import PostDeckArchetypeResponseDto from "./dto/deckArchetype/postDeckArchetypeResponseDto";
import GetAllCachedDataDto from "./dto/GetAllCachedDataDto";
import { ContentWithoutDeckChildListItemDto } from "./dto/ContentFlatDto";
import GetDeckChildByIdDto from "./dto/deckChild/getDeckChildByIdDto";
import { PostDeckChildResponseDto } from "./dto/deckChild/postDeckChildResponseDto";
import { PatchDeckChildResponseDto } from "./dto/deckChild/patchDeckChildResponseDto";
import GetContentCreatorWithAllDetailsByIdDto from "./dto/ContentCreator/getContentCreatorWithAllDetailsByIdDto";
import { GetCardInfoFromListOfCards } from "./dto/card/GetCardInfoFromCardListDto";

const sleep = (delay: number) => {
    return new Promise((resolve)=>{
        setTimeout(resolve, delay)
    })
}

axios.defaults.baseURL = process.env.REACT_APP_API_URL

axios.interceptors.response.use(async response => {
    if(process.env.NODE_ENV === 'development') await sleep(10);
        const pagination = response.headers['pagination']
        if(pagination){
            response.data = new PaginatedResult(response.data, JSON.parse(pagination))
            return response as AxiosResponse<PaginatedResult<any>>
        }
        return response;
}, (error: AxiosError) => {
    const { data, status } = error.response as AxiosResponse
    
    switch(status){
        case 400:
            
            if (error.config!.method === 'get' && data.errors && Object.prototype.hasOwnProperty.call(data.errors, 'id')) {
                router.navigate('/not-found')
            }
            

            // if (config.method === 'get' && data.errors.hasOwnProperty('id')) {
            //     router.navigate('/not-found');
            // }

            if(data.errors) {
                const modalStateErrors = []
                for( const key in data.errors) {
                    if(data.errors[key]){
                        modalStateErrors.push(data.errors[key])
                    }
                }
                throw modalStateErrors.flat()
            }else {
                toast.error(data)
            }
            break;
            case 401:
                toast.error('unauthorised')
                break;
            case 403:
                toast.error("forbidden")
                break;
            case 404:
                store.commonStore.setServerError(data)
                router.navigate('/not-found')
                break;
            case 500:
                store.commonStore.setServerError(data)
                router.navigate('/server-error')
                break;
    }
    return Promise.reject(error)
})

const responseBody = <T> (response: AxiosResponse<T>) => {
    return response.data;
}

axios.interceptors.request.use(config => {
    const token = store.commonStore.token
    if(token && config.headers) config.headers.Authorization = `Bearer ${token}`
    return config
})

axios.interceptors.request.use(config => {
    const token = store.commonStore.token;
    if (token && config.headers) config.headers.Authorization = `Bearer ${token}`
    return config
})

const requests = {
    get: <T> (url: string) => axios.get<T>(url).then(responseBody),
    post: <T> (url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T> (url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    patch: <T> (url: string, body: {}) => axios.patch<T>(url, body).then(responseBody),
    delete: <T> (url: string) => axios.delete<T>(url).then(responseBody),
}

const Activities = {
    list: (params: URLSearchParams)=> axios.get<PaginatedResult<Activity[]>>('/activities', {params}).then(responseBody),
    details: (id: string) => requests.get<Activity>(`/activities/${id}`),
    create: (activity: ActivityFormValues ) => requests.post<void>('activities', activity),
    update: (activity: ActivityFormValues) => requests.put<void>(`/activity/${activity.id}`, activity),
    delete: (id: string) => requests.delete<void>(`/activities/${id}`),
    attend: (id: string) => requests.post<void>(`/activities/${id}/attend`, {}),
}

const Account = {
    current: ()=> requests.get<User>('/account'),
    login: (user: UserFormValues) => requests.post<User>('/account/login', user),
    register: (user: UserFormValues) => requests.post<User>('/account/register', user),
}

const CachedData = {
    getAllCachedData: ()=> requests.get<GetAllCachedDataDto>(`cachedData`),
}

const Profiles = {
    get: (username: string) => requests.get<Profile>(`profiles/${username}`),
    uploadPhoto: (file: Blob) => {
        let formData = new FormData()
        formData.append('File', file)
        return axios.post<Photo>('photos', formData, {
            headers: {'Content-type': 'multipart/form-data'}
        })},
    setMainPhoto: (id: string) => requests.post(`/photos/${id}/setMain`, {}),
    deletePhoto: (id: string) => requests.delete(`photos/${id}`),
    updateProfile: (profile: Partial<Profile>) => requests.put(`/profile`, profile),
    updateFollowing: (username: string) => requests.post(`/follow/${username}`, {}),
    listFollowings: (username: string, predicate: string) => requests.get<Profile[]>(`/follow/${username}?predicate=${predicate}`),
    listActivities: (username: string, predicate: string) => requests.get<UserActivity[]>(`/profiles/${username}/activities?predicate=${predicate}`)
}

const DeckChild = {
    getDeckChildById: (id: number) => requests.get<GetDeckChildByIdDto>(`deckchild/${id}`),
    getAllDeckChildListItems : (formatId : number, bestOfOne : boolean) => requests.get<GetAllDeckChildListItemsDto[]>(`deckchild/allListItems?formatId=${formatId}&bestOfOne=${bestOfOne}`),
    postDeckChild: (postDeckChildRequestDto: PostDeckChildRequestDto) => requests.post<PostDeckChildResponseDto>(`deckchild`, postDeckChildRequestDto),
    patchDeckChild : (patchDeckChildRequestDto: PatchDeckChildRequestDto) => requests.patch<PatchDeckChildResponseDto>(`deckchild`, patchDeckChildRequestDto),
    deleteDeckChild : (id : number) => requests.delete<boolean>(`deckchild/${id}`)
}

const ContentCreator = {
    getContentCreatorById: (id: number) => requests.get<GetContentCreatorByIdDto>(`ContentCreator/${id}`),
    getContentCreatorWithAllDetailsById: (id: number) => requests.get<GetContentCreatorWithAllDetailsByIdDto>(`ContentCreator/allDetails/${id}`),
    getAllContentCreators : () => requests.get<GetAllContentCreatorsDto[]>(`ContentCreator/getAll`),
    postContentCreator: (postContentCreatorRequestDto: PostContentCreatorRequestDto) => requests.post<PostContentCreatorResponseDto>(`ContentCreator`, postContentCreatorRequestDto),
    patchContentCreator : (patchContentCreatorRequestDto: PatchContentCreatorRequestDto) => requests.patch<PatchContentCreatorResponseDto>(`ContentCreator`, patchContentCreatorRequestDto),
    deleteContentCreator : (id: number) => requests.delete<number>(`ContentCreator/${id}`)
}

const Content = {
    getWithoutDeckChildListItems : (platformId : number = -1, includeDeckChildId : number = -1) => {
        let queryParams = new URLSearchParams();
        if (platformId !== -1) {
            queryParams.append("platformId", platformId.toString());
        }
        if (includeDeckChildId !== -1) {
            queryParams.append("includeDeckChildId", includeDeckChildId.toString());
        }
        const url = `content/ListwithoutDeckChildItems?${queryParams.toString()}`;
        return requests.get<ContentWithoutDeckChildListItemDto[]>(url);
    }
}

const DeckArchetype = {
    getDeckArchetypeWithDeckChildsById: (id: number, bestOfOne?: boolean) => requests.get<GetDeckArchetypeWithDeckChildsByIdDto>(`deckArchetype/${id}${bestOfOne !== undefined ? `?bestOfOne=${bestOfOne}` : ``}`),
    getDeckArchetypesByFormatId: (formatId: number) => requests.get<GetDeckArchetypesByFormatIdDto[]>(`deckArchetype/by-format-id/${formatId}`),
    getDeckArchetypeAllListItems: (formatId : number | undefined, bestOfOne : boolean) => requests.get<GetDeckArchetypeAllListItemDto[]>(`deckArchetype/allListItems?formatId=${formatId}&bestOfOne=${bestOfOne}`),
    getDeckArchetypeById: (id: number) => requests.get<GetDeckArchetypeByIdDto>(`deckArchetype/flat/${id}`),
    postDeckArchetype: (postDeckArchetypeRequestDto: PostDeckArchetypeRequestDto) => requests.post<PostDeckArchetypeResponseDto>(`deckArchetype`, postDeckArchetypeRequestDto),
    patchDeckArchetype: (patchDeckArchetypeRequestDto: PatchDeckArchetypeRequestDto) => requests.patch<PatchDeckArchetypeResponseDto>(`deckArchetype/`, patchDeckArchetypeRequestDto)
}

const Cards = {
    getCardAutocompleteByToken: (token: string) => requests.get<GetCardAutocompleteByTokenDto[]>(`/card/autocomplete/${token}`),
    getCardByNameId: (nameId: string) => requests.get<GetCardByNameIdDto>(`/card/${nameId}`),
    CheckIfCardsExists : (checkIfThoseCardsExists: string[]) => {
        const queryString = checkIfThoseCardsExists
            .map(cardId => `nameId=${encodeURIComponent(cardId)}`)
            .join('&');
        return requests.get<string[]>(`/card/checkcards?${queryString}`);
    },
    GetCardInfoFromListOfCards : (cardToReturnInfo: string[]) => {
        const queryString = cardToReturnInfo
            .map(cardId => `nameId=${encodeURIComponent(cardId)}`)
            .join('&');
    
        return requests.get<GetCardInfoFromListOfCards>(`/card/getCardInfoFromCardList?${queryString}`);
    },
}

const News = {
    list: (params: URLSearchParams) => axios.get<PaginatedResult<NewsFlatListItemDto[]>>('/news', { params }).then(responseBody),
}

const Maintenance = {
    updateCardDatabase : () => axios.post('/maintenance/updateCardDatabase').then(responseBody),
    updateImages : () => axios.post('/maintenance/updateImages').then(responseBody),
    values : () => axios.get('/maintenance/values').then(responseBody),
}


const agent = {
    Activities,
    Account,
    Profiles,
    DeckChild,
    DeckArchetype,
    Cards,
    CachedData,
    ContentCreator,
    Content,
    News,
    Maintenance
}

export default agent;