import React, { useState, useEffect } from 'react'
import { Button, Card, Grid, Icon, Input, Loader } from 'semantic-ui-react'
import { Link, useNavigate } from "react-router-dom"
import { useStore } from '../../../../app/stores/store'
import { observer } from 'mobx-react-lite'
import RenderManaCost from '../../../../app/common/RenderManaCost'
import CacheService from '../../../../CacheService'

const ViewByArchetypeList = ({ formatId, bestOfOne }: { formatId: number, bestOfOne : boolean }) => {

  const [searchTerm, setSearchTerm] = useState('')
  const [isExpanded, setIsExpanded] = useState(false)
  const navigate = useNavigate()
  const { userStore, deckArchetypeStore } = useStore()

  useEffect(() => {
    const fetchDeckChilds = async () => {
      deckArchetypeStore.getListItems(formatId, bestOfOne)
    };
    fetchDeckChilds();
  }, [formatId, bestOfOne, deckArchetypeStore]);

  const filteredArchetypeDeckListItems = deckArchetypeStore.deckArchetypes.filter(item =>
    item.name && (item.name.toLowerCase().includes(searchTerm.toLowerCase()))
  )

  const handleEditClick = (event: React.MouseEvent<HTMLButtonElement>, deckArchetypeId: number) => {
    event.preventDefault();
    event.stopPropagation();
    navigate(`/createAndEditArchetype/${deckArchetypeId}`, { replace: true })
  };

  const handleDeckClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, deckArchetypeId: number, deckChildId: number) => {
    event.preventDefault();
    event.stopPropagation();
    navigate(`/deckArchetypeView/${deckArchetypeId}/${deckChildId}`, { replace: true })
  };

  if (deckArchetypeStore.loading) {
    return <Loader active inline="centered" />
  }

  return (
    <>
      <Grid centered style={{ marginTop: '20px' }}>
        <Grid.Column style={{ maxWidth: 450 }}>
          <div style={{ border: '2px solid #ddd', padding: '4px', borderRadius: '2px' }}>
            <Input
              icon='search'
              placeholder='Search decks...'
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              style={{
                width: '100%',
                boxSizing: 'border-box',
              }}
            />
            {/* {isExpanded && (
              <>
                Expanded Area
              </>
            )} */}

            <Button type='button' size='tiny' onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? 'Show Less' : 'Show More'}
            </Button>
          </div>
        </Grid.Column>
      </Grid>
      <Grid columns={4} stackable>
        <Grid.Row>
          {filteredArchetypeDeckListItems.map(item => (
            <Grid.Column key={item.id} style={{ marginBottom: '20px' }}>
              <Card as={Link} to={`/deckArchetypeView/${CacheService.getInstance().formats[formatId]}/${bestOfOne ? "BO1" : "BO3"}/${item.id}`} style={{ height: '200px', display: 'flex', flexDirection: 'column' }}>
                <Card.Content style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                  <Card.Header style={{ display: 'flex', alignItems: 'center' }}>
                    <span><h3>{item.name ?? 'EMPTY'}</h3></span>
                    {userStore.isLoggedIn ?
                      <span style={{ marginLeft: '10px' }}>
                        <Button icon size='tiny' onClick={(e) => handleEditClick(e, item.id)}>
                          <Icon name='edit' />
                        </Button>
                      </span>
                      : null}
                  </Card.Header>
                  <Card.Description>
                    {item.deckChilds.map(deckChild =>
                      <div key={deckChild.id}>
                        <span
                          style={{ color: 'inherit', textDecoration: 'underline', cursor: 'pointer', marginRight: "4px" }}
                          onClick={(e) => handleDeckClick(e, item.id, deckChild.id)}
                        >
                          {deckChild.name}
                        </span>
                        {RenderManaCost({ colors: deckChild.colorCombinationId })}
                      </div>)
                    }
                  </Card.Description>
                </Card.Content>
              </Card>
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </>
  );
}

export default observer(ViewByArchetypeList)