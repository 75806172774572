import { useState, useEffect, useCallback } from 'react';
import { Dropdown, Button, SemanticCOLORS } from 'semantic-ui-react';
import _debounce from 'lodash/debounce';
import DeckCardInfo from '../../../app/models/deckCard';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import GetCardAutocompleteByTokenDto from '../../../app/api/dto/card/GetCardAutocompleteByTokenDto';
  
const AddCardToDeckChildFieldComponent = ({ handler }: {handler : (value : DeckCardInfo)=> void}) => {
  const [selectedToken, setSelectedToken] = useState<string>("");
  const [selectedItem, setSelectedItem] = useState<GetCardAutocompleteByTokenDto | null>(null);
  const { cardStore } = useStore()

  const debouncedFetchData = useCallback((token: string) => {
    _debounce(() => cardStore.fetchCardAutocomplete(token), 50)();
  }, [cardStore])

  const handleButtonClick = (locationId: number) => {
    if (selectedItem) {
      handler(DeckCardInfo.createFromAnyObject({...selectedItem, name: selectedItem.name, defaultLocationId: locationId, quantity: 1}))
    }
  }

  useEffect(() => {
    if (selectedToken.length > 2) {
      debouncedFetchData(selectedToken);
    }
  }, [selectedToken, debouncedFetchData]);

  const dropdownOptions = cardStore.autocompleteOptions.map((value) => ({
    key: value.name,
    value: value.name,
    text: value.name,
  }))

  const buttonsMap = [
    { action: 'mainboard', color: 'blue', label: 'Mainboard', locationId: 1 },
    { action: 'sideboard', color: 'black', label: 'Sideboard', locationId: 2 },
    { action: 'maybeboard', color: 'orange', label: 'Maybeboard', locationId: 6 },
  ]

  return (
    <div>
      <Dropdown
        selection
        options={dropdownOptions}
        placeholder="Select an option"
        search
        onSearchChange={(_, data) => {
          setSelectedToken(data.searchQuery);
        }}
        onChange={(_, data) => {
          const selectedCard = data.value;
          const selectedItem = cardStore.autocompleteOptions.find((option) => option.name === selectedCard);
          setSelectedItem(selectedItem || null);
        }}
      />
      {buttonsMap.map((button) => (
        <Button
          key={button.action}
          color={button.color as SemanticCOLORS}
          size="mini"
          onClick={() => handleButtonClick(button.locationId)}
          disabled={!selectedItem}
        >
          {button.label}
        </Button>
      ))}
    </div>
  )
}

export default observer(AddCardToDeckChildFieldComponent)