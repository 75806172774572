import { Fragment, useState } from 'react'
import { observer } from "mobx-react-lite";
import { ComparisonResult } from './DeckArchetypeView';
import { Button, Grid, Icon, Image, Segment, Table } from 'semantic-ui-react';
import DeckChild from '../../../app/models/deckChild';
import DeckCardInfo from '../../../app/models/deckCard';
import YouTubeVideoComponent from './YoutubeVideoComponent';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import CacheService from '../../../CacheService';
import RenderManaCost from '../../../app/common/RenderManaCost';
import useWindowSize from '../../../app/common/WindowsSize';
import { useStore } from '../../../app/stores/store';
import DeckChildExportView from './DeckChildExportView';
import { Link } from 'react-router-dom';

function cardTypeQuantity(cardList: DeckCardInfo[] = []) {
    return cardList.reduce((sum, current) => sum + current.quantity, 0)
}

const PHONE_WIDTH = 768

const DeckChildNormalListComponent = ({ deckChild, comparisonList, isSelected = 'no' }: { deckChild: DeckChild, comparisonList?: { [key: string]: ComparisonResult } | null, isSelected?: 'no' | 'selected' | 'comparedTo' }) => {
    const { width } = useWindowSize();
    const isMobile = width ? width <= PHONE_WIDTH : false;
    const { modalStore, userStore } = useStore();
    const [hoveredCard, setHoveredCard] = useState<string | null>(null);
    const [imageHoverPosition, setImageHoverPosition] = useState<{}>({});



    const handleMouseEnter = (cardIdentifier: string, event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        const cardRect = event.currentTarget.getBoundingClientRect()
        const viewportHeight = window.innerHeight
        const spaceBelow = viewportHeight - cardRect.bottom
        const imageHeight = 200

        let imagePosition: {};
        if (spaceBelow < imageHeight && cardRect.top > imageHeight) {
            imagePosition = {
                position: 'fixed',
                bottom: viewportHeight - cardRect.top + 5,
                left: cardRect.left + 50,
            }
        } else {
            imagePosition = {
                position: 'fixed',
                top: cardRect.bottom + 5,
                left: cardRect.left + 50,
            };
        }

        setImageHoverPosition(imagePosition)
        setHoveredCard(cardIdentifier)
    };

    function getCardChanges(card: DeckCardInfo): { style: { color?: "green" | "red" | "orange" }, value: string } {

        let style: { color?: "green" | "red" | "orange" } = {};
        let value: string = "";
        if (comparisonList) {
            let selectedCard = comparisonList[card.compositeKey];
            if (selectedCard && selectedCard.status) {
                switch (selectedCard?.status) {
                    case 'added':
                        style = { color: "green" }
                        break;
                    case 'removed':
                        style = { color: "red" }
                        break;
                    case 'changed':
                        style = { color: "orange" }
                        if (isSelected === 'comparedTo') {
                            const diff = selectedCard.compareToQuantity - selectedCard.selectedQuantity;
                            value = diff > 0 ? `+${diff}` : `${diff}`;
                        }
                        break;
                    case 'unchanged':

                        break;
                }
            }
        }
        return { style, value }
    }

    function ShowCardPictures(cardName: string) {
        return <div style={{
            position: 'fixed',
            zIndex: 10,
            ...imageHoverPosition,
        }}>
            <Image src={`https://rulezeromagiccards.s3.eu-north-1.amazonaws.com/magic/${fixedName(cardName)}`} size="tiny" wrapped ui={false} width={200} />
        </div>;
    }

    function fixedName(cardName: string): string {

        let fixedName = cardName
            .trim()
            .replace(/ /g, '_')
            .replace('//', '')
            .replace(/[^\w\-.]/g, '')

        return fixedName;
    }

    const renderCardsByType = (cards: DeckCardInfo[], type: string) => (
        <Fragment key={type}>
            <Table.Row style={{ padding: "0" }} >
                <Table.Cell colSpan={2} style={{ padding: '1px', border: 'none', textTransform: 'capitalize' as 'capitalize', paddingLeft: 4, fontWeight: "bold" }}>
                    {type} ({cardTypeQuantity(cards)})
                </Table.Cell>
            </Table.Row>
            {cards.map((card) => {
                const cardChange = getCardChanges(card);
                return (
                    <Table.Row style={{ padding: "0" }} key={card.compositeKey}
                        onMouseEnter={(e: any) => handleMouseEnter(card.compositeKey, e)}
                        onMouseLeave={() => setHoveredCard(null)}
                    >
                        {isMobile ? (
                            <Table.Cell colSpan={2} style={{ fontSize: "16px", paddingTop: 1, paddingBottom: '10px', border: 'none', fontWeight: 400 }}>
                                {hoveredCard === card.compositeKey && ShowCardPictures(card.name)}
                                <span style={{ ...cardChange.style }}>
                                    {isSelected === "comparedTo" && comparisonList ? (
                                        <span style={{ width: "20px", display: "inline-block", color: "black", textAlign: "end" }}>
                                            {cardChange.value}
                                        </span>
                                    ) : ""} {card.quantity} {card.name}
                                </span>
                                <span className="deck-list-entry-mana-cost" style={{ marginLeft: "10px" }}>
                                    {RenderManaCost({ colors: card.manaCost })}
                                </span>
                            </Table.Cell>
                        ) : (
                            <>
                                <Table.Cell style={{ ...cardChange.style, fontSize: "16px", padding: '1px 10px', border: 'none' }}>
                                    <span>
                                        {isSelected === "comparedTo" && comparisonList ? (
                                            <span style={{ width: "20px", display: "inline-block", color: "black", textAlign: "end" }}>
                                                {cardChange.value}
                                            </span>
                                        ) : ""} {card.quantity} {card.name}
                                    </span>
                                    {hoveredCard === card.compositeKey && ShowCardPictures(card.name)}
                                </Table.Cell>
                                <Table.Cell style={{ padding: '1px', border: 'none' }}>
                                    <span className="deck-list-entry-mana-cost">
                                        {RenderManaCost({ colors: card.manaCost })}
                                    </span>
                                </Table.Cell>
                            </>
                        )}
                    </Table.Row>
                );
            })}
        </Fragment>
    );

    return (
        <>
            <Segment raised>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={6}>
                            <h1>{deckChild.name}</h1>
                            {userStore.isLoggedIn ?
                                <Button as={Link} icon size='tiny' to={`/createAndEditDeck/${deckChild.id}`} style={{ marginLeft: '10px' }}>
                                    <Icon name='edit' />
                                </Button>
                                : null
                            }
                        </Grid.Column>
                        <Grid.Column width={10} textAlign="right">
                            <Button primary onClick={() => modalStore.openModal(<DeckChildExportView deckChild={deckChild} />)}>Export</Button>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <div style={{ columnCount: isSelected === 'no' && (width ? width >= PHONE_WIDTH : false) ? 2 : 1 }}>
                                <Table celled striped>
                                    <Table.Body>
                                        {
                                            Object.entries(deckChild.returnCardsByLocationAndType([1, 2])).map(([locationId, typesMap]) => (
                                                <Fragment key={locationId}>
                                                    <Table.Row>
                                                        <Table.Cell colSpan={3} style={{ padding: '1px', border: 'none', fontSize: "16px", fontWeight: "bolder" }}>
                                                            {CacheService.getInstance().defaultLocations[Number(locationId)] || 'Unknown Location'}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                    {Object.entries(typesMap).map(([type, cards]) => (
                                                        cards.length > 0 && renderCardsByType(cards, type)
                                                    ))}
                                                </Fragment>
                                            ))
                                        }
                                    </Table.Body>
                                </Table>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            <Segment raised>
                <Grid.Row>
                    <Grid.Column>
                        <h1>Contents</h1>
                    </Grid.Column>
                </Grid.Row>
                {deckChild.contents && deckChild.contents.length > 0 ?
                    deckChild.contents.map(content =>
                        <Grid key={content.id} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Grid.Row>
                                <Grid.Column textAlign='center'>
                                    {CacheService.getInstance().platforms[content.platformId] === "YouTube" ? <YouTubeVideoComponent videoId={content.url} /> : null}
                                    {CacheService.getInstance().platforms[content.platformId] === "Twitter" ? <TwitterTweetEmbed options={{ height: 400, width: 1000 }} tweetId={content.url} /> : null}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    ) : "No Content Yet. Go Make Some!"
                }
            </Segment>
        </>
    );
}

export default observer(DeckChildNormalListComponent)