import { Button, Card, Grid } from 'semantic-ui-react'
import DeckChild from '../../../app/models/deckChild'
import RenderManaCost from '../../../app/common/RenderManaCost'
import format from 'date-fns/format'
import CacheService from '../../../CacheService'

interface Props {
    decks: DeckChild[]
    selectedDeck? : DeckChild | null
    onDeckSelected: (selectedDeckIndex: DeckChild) => void
    onCompare: (compareDeck: DeckChild) => void
}

const ArchetypeDeckChildListComponent = ({ decks, selectedDeck, onCompare, onDeckSelected } : Props) => {
  return (
    <Grid centered stackable doubling columns={4} divided>
      <Grid.Row>
        {decks.map((deck) => (
          <Grid.Column key={deck.id} mobile={8} tablet={4} computer={4} style={{ marginBottom: '20px' }}>
            <Card
              centered
              onClick={() => onDeckSelected(deck)}
              color={selectedDeck && selectedDeck.id === deck.id ? 'blue' : undefined}
            >
              <Card.Content>
                <Card.Header style={{height: "44px", overflow : 'hidden', fontSize : "18px"}} >{deck.name}</Card.Header>
                <Card.Description>
                  {<RenderManaCost colors={deck.colorCombinationId} />}
                </Card.Description>
                <Card.Description>
                    {CacheService.getInstance().deckLatestStates[deck.deckLatestState]} on {format(new Date(deck.updatedDate), 'dd MMM')}
                </Card.Description>
              </Card.Content>
              {selectedDeck && selectedDeck.id === deck.id ? (
                <Card.Content extra>
                  Selected
                </Card.Content>
              ) : selectedDeck !== null && (
                <Card.Content extra>
                  <Button 
                    basic
                    color='blue' 
                    size='tiny'
                    style={{padding: '5px 10px'}}
                    onClick={(e) => {
                        e.stopPropagation();
                        onCompare(deck);
                    }}
                  >
                    Compare
                  </Button>
                </Card.Content>
              )}
            </Card>
          </Grid.Column>
        ))}
      </Grid.Row>
    </Grid>
  );
};

export default ArchetypeDeckChildListComponent;
