import { Dropdown } from 'semantic-ui-react';
import { useStore } from '../../../app/stores/store';
import LoginForm from '../../Users/LoginForm';

const LoginView = () => {

    const { userStore, modalStore } = useStore();

    return (
        <>
            {userStore.isLoggedIn ? (
                <Dropdown.Item onClick={userStore.logout} text='Logout' icon='power' />
            ) : (
                <Dropdown.Item onClick={() => modalStore.openModal(<LoginForm />)} text='Login' icon='power' />
            )}
        </>
    )
}

export default LoginView