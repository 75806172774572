import { makeAutoObservable, runInAction } from "mobx";
import ContentCreator, { Content } from "../models/contentCreator";
import agent from "../api/agent";

export default class ContentCreatorStore {
    contentCreator = ContentCreator.createEmptyObject()
    contentCreators : ContentCreator[] = []
    contents : Content[] = []
    updating = false
    loading = false
    loadingList = false

    constructor() {
        makeAutoObservable(this);
    }

    loadContentCreatorById = async (id: number, details : boolean = false) => {
        this.setLoading(true);
        try {
            const foundContentCreator = await (details ? agent.ContentCreator.getContentCreatorWithAllDetailsById(id) : agent.ContentCreator.getContentCreatorById(id));
            runInAction(() => this.contentCreator = ContentCreator.createFromAnyObject(foundContentCreator))
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            this.setLoading(false);
        }
    }

    loadAllContentCreators = async () => {
        this.setLoadingList(true);
        try {
            const response = await agent.ContentCreator.getAllContentCreators();
            runInAction(() => this.contentCreators = response.map(contentCreatorItem => ContentCreator.createFromAnyObject(contentCreatorItem))) 
        } catch (error) {
            console.log(error)
            throw error
        } finally {
            this.setLoadingList(false);
        }
    }

    createOrUpdate = async () : Promise<ContentCreator> => {
        this.setUpdating(true)
        try {
            var newLocal: any;
            if (this.contentCreator.id === 0) {
                newLocal = await agent.ContentCreator.postContentCreator(this.contentCreator.convertToPostContentCreatorRequestDto());
            } else {
                newLocal = await agent.ContentCreator.patchContentCreator(this.contentCreator.convertToPatchContentCreatorRequestDto());
            }
            runInAction(() => this.contentCreator = ContentCreator.createFromAnyObject(newLocal))
            return this.contentCreator
        } catch (error: any) {
            throw error
        } finally {
            this.setUpdating(false)
        }
    }

    delete = async () => {
        this.setUpdating(true)
        try {
            await agent.ContentCreator.deleteContentCreator(this.contentCreator.id);
            runInAction(() => this.contentCreator = ContentCreator.createEmptyObject())
            return this.contentCreator
        } catch (error: any) {
            throw error
        } finally {
            this.setUpdating(false)
        }
    }

    onContentCreatorChangeHandler = (predicate: string, payload: any) => {
        switch (predicate) {
            case "changeName":
                runInAction(() => {
                    this.contentCreator = ContentCreator.createFromObject({ ...this.contentCreator, name: payload })
                })
                break
            case "addContent":
                runInAction(() => {
                    const updatedContents = [...this.contentCreator.contents, Content.createEmpty()];
                    this.contentCreator = ContentCreator.createFromObject({ ...this.contentCreator, contents: updatedContents })
                })
                break
                case "updateContent":
                    runInAction(() => {
                        const index = this.contentCreator.contents.findIndex(content => content.id === payload.id);
                        if (index !== -1) {
                            const updatedContents = this.contentCreator.contents.map((content, i) =>
                                i === index ? { ...payload } : content
                            );
                            this.contentCreator = ContentCreator.createFromObject({
                                ...this.contentCreator,
                                contents: updatedContents
                            });
                        }
                    });
                    break;
            case "socialMedia":
                runInAction(() => {
                    var socialMedias = this.contentCreator.contentCreatorSocialMedias;
                    var socialMediaIndex = socialMedias.findIndex(socialMedia => socialMedia.platformId === payload.platformId);
                    if (payload.username === "") {
                        if (socialMediaIndex !== -1) {
                            socialMedias.splice(socialMediaIndex, 1);
                        }
                    } else {
                        if (socialMediaIndex === -1) {
                            socialMedias.push({
                                id: 0,
                                platformId: payload.platformId,
                                username: payload.username
                            });
                        } else {
                            socialMedias[socialMediaIndex].username = payload.username;
                        }
                    }
                    this.contentCreator = ContentCreator.createFromObject({
                        ...this.contentCreator,
                        contentCreatorSocialMedias: socialMedias
                    });
                });
                break;
            case "removeContent":
                runInAction(() => {
                    this.contentCreator.contents = this.contentCreator.contents.filter(content => content.id !== payload);
                    this.contentCreator = ContentCreator.createFromObject({...this.contentCreator})
                });
                break;
        }
    }

    empty = () => {
        runInAction(() => {
            this.contentCreator = ContentCreator.createEmptyObject()
        })
    }

    setUpdating(state: boolean) {
        this.updating = state
    }

    setLoading(state: boolean) {
        this.loading = state
    }

    setLoadingList(state: boolean) {
        this.loadingList = state
    }

}
