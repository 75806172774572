import { makeAutoObservable, runInAction } from "mobx";
import Format from "../models/format";
import agent from "../api/agent";
import DeckArchetype from "../models/deckArchetype";
import * as yup from 'yup';

export default class DeckArchetypeStore {
    formats: Format[] = [];
    deckArchetype : DeckArchetype = DeckArchetype.createEmpty();
    deckArchetypes : DeckArchetype[] = [];
    loading = false;
    loadingInitial = false;
    submitting = false;
    loadingList = false;

    constructor() {
        makeAutoObservable(this);
    }

    loadDeckArchetype = async (id: number, deckBestOfOne? : boolean) => {
        this.setLoadingInitial(true);
        try {
            const deckArchetypeByIdDto = await agent.DeckArchetype.getDeckArchetypeWithDeckChildsById(id, deckBestOfOne);
            runInAction(() => {
                this.deckArchetype = DeckArchetype.createFromDto(deckArchetypeByIdDto);
            });
        } catch (error) {
            console.log(error);
        } finally {
            this.setLoadingInitial(false);
        }
    };

    loadDeckArchetypeFlatById = async (id: number) => {
        try {

            var result = await agent.DeckArchetype.getDeckArchetypeById(id);
            runInAction(()=>{
                this.deckArchetype = DeckArchetype.createFromDto(result);
            })
             
        } catch (error) {
            throw error;
        }
    };

    setDeckArchetypeEmpty = () => {
        this.deckArchetype = DeckArchetype.createEmpty();
    };

    setDeckArchetypeNull = () => {
        this.deckArchetype = DeckArchetype.createEmpty();
    };

    createDeckArchetype = async (deckArchetype: DeckArchetype): Promise<DeckArchetype> => {
        this.setSubmitting(true);
        try {
            const result = DeckArchetype.createFromDto(await agent.DeckArchetype.postDeckArchetype(deckArchetype.toSimpleObject()));
            runInAction(()=>{
                this.deckArchetype = result
            })
            return this.deckArchetype;
        } catch (error) {
            console.error(error);
            throw error;
        } finally {
            this.setSubmitting(false);
        }
    };

    updateDeckArchetype = async (deckArchetype: DeckArchetype) => {
        this.setSubmitting(true);
        try {
            const result = await agent.DeckArchetype.patchDeckArchetype(deckArchetype.toSimpleObject());
            runInAction(()=>{
                this.deckArchetype = DeckArchetype.createFromDto(result)
            })
            
        } catch (error) {
            console.error(error);
            throw error;
        } finally {
            this.setSubmitting(false);
        }
    };

    getListItems = async (formatId : number, bestOfOne : boolean) => {
        this.setLoading(true);
        try {
            const result = await agent.DeckArchetype.getDeckArchetypeAllListItems(formatId, bestOfOne);
            runInAction(()=>{
                this.deckArchetypes = result.map(deckArchetypeListItem => DeckArchetype.createFromDto(deckArchetypeListItem))
            })
        } catch (error) {
            console.error(error);
            throw error;
        } finally {
            this.setLoading(false);
        }
    };

    getByFormatId = async (formatId: number) => {
        this.setLoadingList(true);
        try {
            const response = await agent.DeckArchetype.getDeckArchetypesByFormatId(formatId);
            runInAction(() => {
                this.deckArchetypes = response.map(deckArchetypeListItem => DeckArchetype.createFromDto(deckArchetypeListItem))
            });
        } catch (error: any) {
            throw error;
        } finally {
            this.setLoadingList(false);
        }
    };

    setLoadingInitial(state: boolean) {
        this.loadingInitial = state;
    }

    setLoading(state: boolean) {
        this.loading = state;
    }

    setSubmitting(state: boolean) {
        this.submitting = state;
    }

    setLoadingList(state: boolean) {
        this.loadingList = state;
    }

    deckArchetypeCreateDtoSchema = yup.object().shape({
        name: yup
          .string()
          .trim()
          .required('Archetype name is required')
          .min(3, 'Archetype name must be at least 3 characters long'),
        formatIds: yup
          .array()
          .of(yup.number().positive().integer())
          .min(1, 'At least one format must be selected')
          .required('Formats are required'),
    });
}
