import { Segment, Header, Dropdown, Button, List } from 'semantic-ui-react';
import ContentStore from '../../../app/stores/contentStore';
import DeckChildStore from '../../../app/stores/deckChildStore';
import { useState } from 'react';
import CacheService from '../../../CacheService';
import ModalStore from '../../../app/stores/modalStore';
import YouTubeVideoComponent from '../DeckArchetypeView/YoutubeVideoComponent';

const AddContentFieldComponent = ({ contentStore, deckStore, modalStore } : {contentStore : ContentStore, deckStore : DeckChildStore, modalStore : ModalStore}) => {
    const [selectedContent, setSelectedContent] = useState<number>(-1);

    const filteredSelectedContent = Object.values(contentStore.contentListWithoutDeckChild).filter(content =>
        !deckStore.deckChild.contents.some(deckContent => deckContent.id === content.id)
    );
    return (
        <Segment raised>
            <Header as='h3'>Deck Content</Header>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Dropdown
                    disabled={contentStore.loading}
                    placeholder='Select Deck Content'
                    selection
                    options={Object.entries(filteredSelectedContent).map(([key, content]) => ({
                        text: `${content.platformId} ${content.url}`,
                        value: content.id
                    }))}
                    value={selectedContent}
                    onChange={(e, { value }) => setSelectedContent(Number(value))}
                    style={{ marginRight: '1em' }}
                />
                <Button 
                    disabled={selectedContent === -1}
                    onClick={() => {deckStore.onDeckChangeHandler("addContent", contentStore.contentListWithoutDeckChild[selectedContent]); setSelectedContent(-1)}}
                    positive
                >
                    Add Content
                </Button>
            </div>

            <List divided relaxed>
                {deckStore.deckChild.contents.map(content => (
                    <List.Item key={content.id}>
                        <List.Content floated='right'>
                            <Button 
                                color={ content.isSource ? 'green' : 'blue'}
                                onClick={() => deckStore.onDeckChangeHandler("contentSourceId", content.id)}
                            >
                                { content.isSource ? 'Selected' : 'Select' }
                            </Button>
                            <Button 
                                color='red'
                                onClick={() => deckStore.onDeckChangeHandler('deleteContent', content.id)}
                            >
                                Remove
                            </Button>
                        </List.Content>
                        <List.Content>
                            <List.Description>
                                <Button onClick={()=>{modalStore.openModal(YouTubeVideoComponent({videoId : content.url}))}}>
                                    {content.contentCreatorName} - {CacheService.getInstance().platforms[content.platformId]} - {content.url}
                                </Button>
                            </List.Description>
                        </List.Content>
                    </List.Item>
                ))}
            </List>
        </Segment>
    );
};

export default AddContentFieldComponent