import './styles.css'
import 'react-toastify/dist/ReactToastify.min.css'
import { Container } from 'semantic-ui-react'
import NavBar from './NavBar'
import { observer } from 'mobx-react-lite'
import { Outlet, ScrollRestoration } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { useStore } from '../stores/store'
import { useEffect } from 'react'
import LoadingComponents from './LoadingComponent'
import CacheService from '../../CacheService'
import ModalContainer from '../common/modals/ModalContainer'

const App = observer(() => {

  const {commonStore, userStore} = useStore()

  useEffect(() => {
    const initializeService = async () => {
      try {
          await CacheService.initialize();
      } catch (error) {
          console.error("Failed to initialize PlatformService", error)
      }
    };
  
    initializeService().then(() => {
      if (commonStore.token) {
        userStore.getUser().finally(() => commonStore.setAppLoaded());
      } else {
        commonStore.setAppLoaded()
      }
    });
  }, [commonStore, userStore])
  if(!commonStore.appLoaded) return <LoadingComponents content='Loading app...' />
  
  return (
    <>
      <ScrollRestoration/>
      <ModalContainer/>
      <ToastContainer position='bottom-right' hideProgressBar theme='colored' />
        <>
            <NavBar/>
            <Container style={{marginTop: '7em', paddingBottom: '120px'}}>
              <Outlet/>
            </Container>
        </>
    </>
  );
})

export default App
